import { Picker } from "emoji-picker-element";
import "./emojiPicker.css";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { createElement, memo, useEffect, useRef, useState } from "react";
import de from "emoji-picker-element/i18n/de";
import en from "emoji-picker-element/i18n/en";
import { useLocales } from "../../../../locales";
import { useClickAway } from "react-use";
import { T } from "@tolgee/react";
import { Tooltip } from "react-bootstrap";
const getResourceUrl = (locale) => {
  // document.getElementById('emojis-de').textContent
  return document.querySelector(`#emojis-${locale}`).textContent;
};
const EmojiPicker = ({ addEmoji }) => {
  const [showPicker, setShowPicker] = useState(false);
  const containerRef = useRef(null);
  useClickAway(containerRef, () => {
    setShowPicker(false);
  });
  // find the cause of re-rendering
  return (
    <div>
      <OverlayTrigger
        placement="top"
        trigger={["click"]}
        show={showPicker}
        overlay={
          <Tooltip className="mytooltip">
            <div ref={containerRef}>
              <div className="d-flex flex-column align-items-stretch">
                <div className="d-flex justify-content-end">
                  <span
                    className="cursor-pointer pe-5 pt-3"
                    onClick={() => setShowPicker(false)}
                  >
                    <i className="fa-duotone fa-times"></i>
                  </span>
                </div>
                <PickerElement addEmoji={addEmoji} />
              </div>
            </div>
          </Tooltip>
        }
      >
        <div
          className="btn emojiButton py-2 px-2"
          onClick={() => setShowPicker(!showPicker)}
        >
          <span>😎</span>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default EmojiPicker;
const PickerElement = memo(({ addEmoji }) => {
  const { currentLang } = useLocales();
  const addEmojiRef = useRef(addEmoji);

  // Update addEmojiRef.current on each render to ensure it always has the latest function
  useEffect(() => {
    addEmojiRef.current = addEmoji;
  }, [addEmoji]);

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      // Initialize the picker with your configuration
      const picker = new Picker({
        locale: currentLang.systemValue,
        i18n: currentLang.systemValue === "de" ? de : en,
        dataSource: getResourceUrl(currentLang.systemValue),
        Theme: "light",
      });
      // Append the picker to the ref's current element
      ref.current.appendChild(picker);
      // Add event listener for emoji click
      picker.addEventListener("emoji-click", (event) => {
        addEmojiRef.current(event.detail.unicode);
        // setShowPicker(false); // Uncomment or handle as needed
      });

      // Set additional properties if needed
      picker.skinToneEmoji = "👍";
      // add z-index to the picker
      picker.style.zIndex = 1000;
      picker.style.width = "100%";
      picker.style.padding = "10px";
      picker.style.paddingTop = "2px";
      // Cleanup function to remove the picker and event listener
      //disable dark mode for emoji picker

      return () => {
        picker.removeEventListener("emoji-click");
        ref?.current?.removeChild(picker);
      };
    }
  }, []);

  return createElement("div", { ref });
});
PickerElement.displayName = "PickerElement";
