export const getDefaultRanges = (): any => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  const lastSevenDays = new Date();
  lastSevenDays.setDate(today.getDate() - 7);
  const thisWeekStart = new Date(today);
  thisWeekStart.setDate(today.getDate() - today.getDay());
  const thisWeekEnd = new Date(thisWeekStart);
  thisWeekEnd.setDate(thisWeekStart.getDate() + 6);

  const lastWeekStart = new Date(thisWeekStart);
  lastWeekStart.setDate(thisWeekStart.getDate() - 7);
  const lastWeekEnd = new Date(thisWeekEnd);
  lastWeekEnd.setDate(thisWeekEnd.getDate() - 7);

  const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);

  const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

  const last30DaysStart = new Date(today);
  last30DaysStart.setDate(today.getDate() - 29);

  const last60DaysStart = new Date(today);
  last60DaysStart.setDate(today.getDate() - 59);

  return [
    {
      label: 'last_seven_days',
      startDate: lastSevenDays,
      endDate: yesterday
    },
    {
      label: 'this_month',
      startDate: thisMonthStart,
      endDate: yesterday
    },
    {
      label: 'last_30_days',
      startDate: last30DaysStart,
      endDate: yesterday
    },
    {
      label: 'last_month',
      startDate: lastMonthStart,
      endDate: lastMonthEnd
    },
    {
      label: 'last_3_months',
      startDate: new Date(today.getFullYear(), today.getMonth() - 3, 1),
      endDate: yesterday
    },
    {
      label: 'last_6_months',
      startDate: new Date(today.getFullYear(), today.getMonth() - 6, 1),
      endDate: yesterday
    },
    {
      label: 'last_12_months',
      startDate: new Date(today.getFullYear(), today.getMonth() - 12, 1),
      endDate: yesterday
    },
    {
      label: 'custom_range',
      startDate: yesterday,
      endDate: yesterday
    }
  ];
};
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export const DEFAULT_RANGE = getDefaultRanges()[0];
export const last28Days = {
  label: 'last_28_days',
  startDate: new Date(new Date().setDate(new Date().getDate() - 28)),
  endDate: new Date()
};
export const last56Days = {
  label: 'last_56_days',
  startDate: new Date(new Date().setDate(new Date().getDate() - 56)),
  endDate: yesterday
};
export const last28daysbefore28days = {
  label: 'last_28_days_before_28_days',
  startDate: new Date(new Date().setDate(new Date().getDate() - 56)),
  endDate: new Date(new Date().setDate(new Date().getDate() - 28))
};
