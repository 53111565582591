import { getFirestore } from '@firebase/firestore';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { getFirebaseToken } from '../redux/slices/user/userDetails';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';

// Initialize the Firebase app
// const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
//     ? firebase.initializeApp({"apiKey": "AIzaSyAUM6p8FYYP1QCpc4BUmxa5mihUho1_5yA","authDomain": "lomavis-prod-eu.firebaseapp.com","databaseURL": "https://lomavis-prod-eu-default-rtdb.europe-west1.firebasedatabase.app","projectId": "lomavis-prod-eu","storageBucket": "lomavis-prod-eu.appspot.com","messagingSenderId": "783431336947","appId": "1:783431336947:web:f87fd1a25204bc8cffc886"})
const app =
  firebase.apps.length === 0
    ? firebase.initializeApp(
        //{"apiKey": "AIzaSyA9x47AgH9FViXt5ZSAOpo6D5majH3lRiQ","authDomain": "lomavis-development.firebaseapp.com","databaseURL": "https://lomavis-development-default-rtdb.europe-west1.firebasedatabase.app","projectId": "lomavis-development","storageBucket": "lomavis-development.appspot.com","messagingSenderId": "951363988806","appId": "1:951363988806:web:91a98779c451b8b5d18947"}
          {"apiKey": "AIzaSyAUM6p8FYYP1QCpc4BUmxa5mihUho1_5yA","authDomain": "lomavis-prod-eu.firebaseapp.com","databaseURL": "https://lomavis-prod-eu-default-rtdb.europe-west1.firebasedatabase.app","projectId": "lomavis-prod-eu","storageBucket": "lomavis-prod-eu.appspot.com","messagingSenderId": "783431336947","appId": "1:783431336947:web:f87fd1a25204bc8cffc886"}
      )
    : firebase.app();

let isAppCheckInitialized = false;

if (!isAppCheckInitialized) {
  // Initialize the Firebase app check
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(

      '6LfV38IgAAAAAOdesgwnW7iA5EEzU3Vd3coVtH2f'
      //'6LeAhKQgAAAAAAfJ9VZzBq0FJwewfDdY4kmoijRI'

    ),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });

  isAppCheckInitialized = true;
}

// Initialize the Firebase authentication and Firestore services
export const firestore = getFirestore(app);

const auth = getAuth();
getFirebaseToken()
  .then((token) => {
    signInWithCustomToken(auth, token)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage, 'error', error);
        // ...
      });
  })
  .catch((error) => {
    console.log(error, 'top lvl');
  });
export const storage = getStorage(app);
export const database = getDatabase(app);
