/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react/prop-types */
import { ReactNode, useEffect, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useLocales } from '../../locales';
import { NewMixedChart } from '../charts/newMixedCharts';
import { CHART_HEIGHT } from '../charts/mixedChart/plotsConfig';
import { set } from 'date-fns';

interface Props {
  display: boolean;
  config: any;
  data: any;
  isLoading: boolean;
  labelsLength: number;
  hasError?: boolean;
  sync?: boolean;
}
// eslint-disable-next-line react/prop-types
const NewPlotTabs: React.FC<Props> = ({
  display,
  config,
  data,
  isLoading,
  labelsLength,
  hasError,
  sync = false
}) => {
  const [hasNullValues, setHasNullValues] = useState<any>(false);
  useEffect(() => {
    for (const key in config) {
      for (const key2 in config[key]) {
        const l = data?.plots?.[key]?.[key2]?.data
          ?.map((item) => item.value)
          .filter((item) => item === null).length;
        if (l > 0) {
          setHasNullValues(true);

          return;
        }
      }
    }
  }, [data, isLoading]);

  const [noData, setNoData] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  useEffect(() => {
    const condition = !data?.plots || Object.keys(data?.plots).length === 0;
    if (condition && !isLoading) {
      setNoData(true);
      return;
    }
    if (!activeTab && data?.plots)
      setActiveTab(
        Object.keys(data?.plots).sort((a, b) => {
          const indexA = Object.keys(config).indexOf(a);
          const indexB = Object.keys(config).indexOf(b);
          return indexA - indexB;
        })[0]
      );
  }, [data, isLoading]);
  const { translate: t } = useLocales();
  switch (true) {
    case hasError:
      return (
        <ChartCard display={display}>
          <div className="alert alert-danger border-0">
            <i className="fa-duotone fa-circle-exclamation me-2"></i>
            {t('mixedChart.error_while_loading_data')}
          </div>
        </ChartCard>
      );
    case sync && noData:
      return (
        <ChartCard display={display}>
          <div className="alert alert-primary border-0">
            <i className="fa-duotone fa-rotate me-2"></i>
            {t('mixedChart.analytics_data_is_currently_synchronizing')}
          </div>
        </ChartCard>
      );
    case noData:
      return (
        <ChartCard display={display}>
          <div className="alert alert-primary border-0">
            <span className="fa fa-info-circle me-2"></span>
            {t('mixedChart.no_data_is_available')}
          </div>
        </ChartCard>
      );
    case !activeTab:
      return (
        <ChartCard display={display}>
          <div className="spinner-border" role="status">
            <span className="sr-only">{t('loading')}</span>
          </div>
        </ChartCard>
      );
    default:
      break;
  }
  return (
    <div>
      <div>
        {hasNullValues && (
          <div className="alert bg-white text-center font-weight-bold" role="alert">
            {sync ? (
              <span className={'text-primary'}>
                {t('mixedChart.data_import_is_currently_in_progress')}
              </span>
            ) : (
              <span className={'text-primary'}>{t('mixedChart.there_is_some_missing_data')}</span>
            )}
          </div>
        )}
      </div>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={activeTab}
        onSelect={(k: string) => {
          setActiveTab(k);
        }}
        mountOnEnter={true}
        unmountOnExit={false}
        activeKey={activeTab}
      >
        {!display ? (
          <Container
            fluid
            className="card d-flex"
            style={{
              boxShadow: '0 -0.7rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
              borderRadius: '0.625rem 0.625rem 0 0'
            }}
          >
            <Row className="card-body p-1">
              <Col xs={12}>
                <Nav variant="pills" className="flex-row">
                  {Object.keys(config).map((item) => {
                    if (config[item as keyof typeof config].additionalPlot)
                      return null;
                    if (!Object.keys(data?.plots || {}).includes(item))
                      return null;
                    return (
                      <Nav.Item key={item}>
                        <Nav.Link
                          eventKey={item}
                          className={`bg-transparent ${
                            activeTab === item ? 'text-dark' : 'text-muted'
                          }`}
                        >
                          <span
                            className="fs-5 mb-0"
                            style={{ fontWeight: 600 }}
                          >
                            {t(item)}
                          </span>
                          {activeTab === item ? (
                            <hr
                              className="text-primary fs-2 "
                              style={{
                                borderTop: '3px solid',
                                opacity: 1,
                                marginTop: 2
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
              </Col>
              <Col xs={12} md={6} className="d-flex justify-content-end"></Col>
            </Row>
          </Container>
        ) : (
          ''
        )}

        <Row>
          <Tab.Content style={{ minHeight: 300 }}>
            {Object.keys(config).map((key) => {
              if (config[key].additionalPlot) return null;
              const currentConfig = {
                ...config[key],
                ...(config[key].addAdditionalPlots
                  ? Object.entries(config).reduce(
                      (acc, [k, value]: [any, any]) => {
                        if (value.additionalPlot) {
                          acc[k] = value;
                        }
                        return acc;
                      },
                      {}
                    )
                  : {})
              };
              const title: string = currentConfig.title
                ? currentConfig.title
                : key;
              delete currentConfig.title;
              delete currentConfig?.addAdditionalPlots;
              return (
                <Tab.Pane
                  key={key}
                  eventKey={key}
                  active={display || key === activeTab}
                >
                  <NewMixedChart
                    labelsLength={labelsLength}
                    display={display}
                    isLoading={isLoading}
                    hasError={hasError}
                    config={currentConfig}
                    label={t(`mixedChart.${title}`)}
                    labels={data?.dates || []}
                    data={{
                      ...data?.plots?.[key],
                      ...data?.additionalData
                    }}
                    messages={[...(data?.messages?.[key] || [])]}
                  />

                  <div className="mb-4" />
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default NewPlotTabs;

interface CardProps {
  display: boolean;
  CARD_CHART_HEIGHT?: number;
  children: ReactNode;
}

const ChartCard: React.FC<CardProps> = ({
  display,
  CARD_CHART_HEIGHT = CHART_HEIGHT,
  children
}) => (
  <div
    className="card card-bordered d-flex justify-content-center align-items-center fade-out mb-4"
    style={{
      boxShadow: '0 1.4rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
      borderRadius: !display ? '0 0 0.625rem 0.625rem' : '0.625rem',
      height: CARD_CHART_HEIGHT ? CARD_CHART_HEIGHT + 200 : undefined
    }}
  >
    {children}
  </div>
);
