import { useTranslate } from "@tolgee/react";
import { FACEBOOK } from "../../../../config/constants";
import { TypeaheadLocationSelection } from "./locationSelectionInput";
import { AdditionalOptionsFirstComment } from "./includes/FirstComment";
import { CreatorVerticalSeparator } from "../include/VerticalSeperator";

export const FacebookAdditionalOptions: React.FC<any> = ({
  platform = FACEBOOK,
}) => {
  const { t } = useTranslate();
  return (
    <div className="border border-secondary px-2 py-2 rounded">
      <AdditionalOptionsFirstComment
        platform={platform}
      ></AdditionalOptionsFirstComment>
      <CreatorVerticalSeparator />
      <TypeaheadLocationSelection
        platform={platform}
        name_key="facebook_location_name"
        id_key="facebook_location_id"
      />
    </div>
  );
};
