import { Config } from '../../../@types/plots';
import {
  ANALYTICS_COLOR_1,
  ANALYTICS_COLOR_2,
  ANALYTICS_COLOR_3,
  ANALYTICS_COLOR_4,
  ANALYTICS_COLOR_YELLOW,
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN_BUSINESS_PAGE,
  TWITTER
} from '../../../config/constants';
import * as icons from '../../../config/icons';
const filters = {
  filters: [
    {
      css_icon: icons.STORY_POST_ICON,
      param: 'content_type_is_story_post',
      tooltip: 'content_type_is_story_post_ony'
    },
    {
      css_icon: icons.TEX_POST_ICON,
      param: 'content_type_is_text_post',
      tooltip: 'content_type_is_text_post'
    },

    {
      css_icon: icons.SINGLE_IMAGE_POST_ICON,
      param: 'content_type_is_single_image_post',
      tooltip: 'content_type_is_single_image_post_ony'
    },
    {
      css_icon: icons.MULTI_IMAGE_POST_ICON,
      param: 'content_type_is_multi_image_post',
      tooltip: 'content_type_is_multi_image_post_ony'
    },
    {
      css_icon: icons.VIDEO_POST_ICON,
      param: 'content_type_is_video_post',
      tooltip: 'content_type_is_video_post_ony'
    },
    {
      css_icon: icons.REEL_POST_ICON,
      param: 'content_type_is_reel_post',
      tooltip: 'content_type_is_reel_post_ony'
    }
  ]
};
export const config: Config = {
  shouldBeCached: true,
  ...filters,
  bar_plot_config: {
    title: 'Posts',
    iconCssClass: 'fas fab-facebook',
    hexColor: ANALYTICS_COLOR_YELLOW,
    type: 'bar'
  },

  metrics: [
    {
      metrics: [100002, 100007, 100012, 100017, 100022, 100023],
      title: 'Likes',
      iconCssClass: 'fas fab-facebook',
      hexColor: ANALYTICS_COLOR_1,
      type: 'line'
    },
    {
      metrics: [100007, 100008, 100011, 100013],
      title: 'Followers',
      iconCssClass: 'optional value',
      hexColor: ANALYTICS_COLOR_2,
      type: 'line'
    },
    {
      metrics: [100001, 100006, 100011, 100016, 100021, 100022],
      title: 'Impressions',
      iconCssClass: 'fas fab-facebook',
      hexColor: ANALYTICS_COLOR_4,
      type: 'line'
    },
    {
      metrics: [100010, 100015, 100020, 100025, 100026],
      title: 'Page visits',
      iconCssClass: 'optional value',
      hexColor: ANALYTICS_COLOR_3,
      type: 'line'
    }
  ]
};
export const configImpressions: Config = {
  ...filters,
  bar_plot_config: {
    title: 'Posts',
    iconCssClass: 'fas fab-facebook',
    hexColor: ANALYTICS_COLOR_YELLOW,
    type: 'bar'
  },

  metrics: [
    {
      metrics: [2],
      title: FACEBOOK,
      iconCssClass: icons.PLATFORM_ICONS[FACEBOOK],
      hexColor: ANALYTICS_COLOR_1,
      type: 'line',
      platform: FACEBOOK
    },
    {
      metrics: [100006],
      title: INSTAGRAM,
      iconCssClass: icons.PLATFORM_ICONS[INSTAGRAM],
      hexColor: ANALYTICS_COLOR_2,
      type: 'line',
      platform: INSTAGRAM
    },
    {
      metrics: [100016],
      title: TWITTER,
      iconCssClass: icons.PLATFORM_ICONS[TWITTER],
      hexColor: ANALYTICS_COLOR_4,
      type: 'line',
      platform: TWITTER
    },
    {
      metrics: [100010, 100015, 100020],
      title: LINKEDIN_BUSINESS_PAGE,
      iconCssClass: icons.PLATFORM_ICONS[LINKEDIN_BUSINESS_PAGE],
      hexColor: ANALYTICS_COLOR_3,
      type: 'line',
      platform: LINKEDIN_BUSINESS_PAGE
    }
  ]
};

export const configEngagement: Config = {
  ...filters,
  bar_plot_config: {
    title: 'Posts',
    iconCssClass: 'fas fab-facebook',
    hexColor: ANALYTICS_COLOR_YELLOW,
    type: 'bar'
  },

  metrics: [
    {
      metrics: [2],
      title: FACEBOOK,
      iconCssClass: icons.PLATFORM_ICONS[FACEBOOK],
      hexColor: ANALYTICS_COLOR_1,
      type: 'line',
      platform: FACEBOOK
    },
    {
      metrics: [100006],
      title: INSTAGRAM,
      iconCssClass: icons.PLATFORM_ICONS[INSTAGRAM],
      hexColor: ANALYTICS_COLOR_2,
      type: 'line',
      platform: INSTAGRAM
    },
    {
      metrics: [100016],
      title: TWITTER,
      iconCssClass: icons.PLATFORM_ICONS[TWITTER],
      hexColor: ANALYTICS_COLOR_4,
      type: 'line',
      platform: TWITTER
    },
    {
      metrics: [100010, 100015, 100020],
      title: LINKEDIN_BUSINESS_PAGE,
      iconCssClass: icons.PLATFORM_ICONS[LINKEDIN_BUSINESS_PAGE],
      hexColor: ANALYTICS_COLOR_3,
      type: 'line',
      platform: LINKEDIN_BUSINESS_PAGE
    }
  ]
};
const configsMap: {
  Growth: Config;
  Impressions: Config;
  Engagement: Config;
  'Video Views': Config;
} = {
  Growth: { ...config },
  Impressions: { ...configImpressions },
  Engagement: { ...configEngagement },
  'Video Views': { ...configImpressions }
};

const platformConfigMap = {
  [FACEBOOK]: configsMap
};
export const getConfig = (
  key: ConfigKeys,
  platform: PlatformConfigKeys
): Config => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!platform) return configsMap[key];
  return platformConfigMap[platform][key];
};
export const getLabels = (platform: PlatformConfigKeys): any[] => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!platform) return Object.keys(configsMap);
  return Object.keys(platformConfigMap[platform]);
};
export type ConfigKeys = keyof typeof configsMap;
export type PlatformConfigKeys = keyof typeof platformConfigMap;

export const CHART_HEIGHT = 350;
