import { useTranslate } from "@tolgee/react";
import {
  LINKEDIN_BUSINESS_PAGE,
  LINKEDIN_PERSONAL_ACCOUNT,
} from "../../../../config/constants";
import { AdditionalOptionsFirstComment } from "./includes/FirstComment";
import { CreatorVerticalSeparator } from "../include/VerticalSeperator";
import { useDispatch, useSelector } from "react-redux";
import { setPostDataAttribute } from "../../../../redux/slices/lomavisCreator/lomavisCreator";
import { useEffect, useState } from "react";
import { MEDIA_TYPE_DOC } from "../../config/constants";
import { set } from "lodash";

export const LinkedinAdditionalOptions: React.FC<any> = ({
  platform = LINKEDIN_BUSINESS_PAGE,
}) => {
  const { t } = useTranslate();
  const {
    postData: {
      [platform]: { linkedin_document_title, media },
    },
  } = useSelector((state: any) => state.lomavisCreator);
  const dispatch = useDispatch();
  const [postHasDocument, setPostHasDocument] = useState(false);
  useEffect(() => {
    setPostHasDocument(
      Boolean(media?.find((m: any) => m.media_type === MEDIA_TYPE_DOC))
    );
  }, [media]);
  return (
    <div className="border border-secondary px-2 py-2 rounded">
      <AdditionalOptionsFirstComment
        platform={platform}
      ></AdditionalOptionsFirstComment>
      <CreatorVerticalSeparator />
      {postHasDocument && (
        <>
          <label htmlFor="linkedin-document-title">
            {t("lomavisCreator.additionalOptions.linkedin_document_title")}
          </label>
          <input
            id="linkedin-document-title"
            type="text"
            value={linkedin_document_title}
            className="form-control form-control-sm"
            maxLength={200}
            onChange={(e) => {
              dispatch(
                setPostDataAttribute({
                  platform: LINKEDIN_PERSONAL_ACCOUNT,
                  attribute: "linkedin_document_title",
                  value: e.target.value,
                })
              );
              dispatch(
                setPostDataAttribute({
                  platform: LINKEDIN_BUSINESS_PAGE,
                  attribute: "linkedin_document_title",
                  value: e.target.value,
                })
              );
            }}
          />
          <span className="text-muted">
            {t(
              "lomavisCreator.additionalOptions.linkedin_document_title_helper"
            )}
          </span>
          <CreatorVerticalSeparator />
        </>
      )}
    </div>
  );
};
