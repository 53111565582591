import { useTranslate } from "@tolgee/react";
import { YOUTUBE } from "../../../../config/constants";
import { Form } from "react-bootstrap";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPostDataAttribute } from "../../../../redux/slices/lomavisCreator/lomavisCreator";
import FirstCommentQuillEditor from "../creatorTextEditor/firstCommentQuillEditor";
import {
  YOUTUBE_AVAILABLE_FOR_KIDS_CHOICES,
  YOUTUBE_CATEGORY_CHOICES,
  YOUTUBE_PRIVACY_STATUS_CHOICES,
} from "../contants";
import { AdditionalOptionsFirstComment } from "./includes/FirstComment";
import { CreatorVerticalSeparator } from "../include/VerticalSeperator";
export const YoutubeAdditionalOptions: React.FC<any> = ({
  platform = YOUTUBE,
}) => {
  const { t } = useTranslate();
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const {
    postData: {
      [YOUTUBE]: {
        youtube_tags,
        youtube_title,
        youtube_category,
        youtube_privacy_status,
        youtube_available_for_kids,
      },
    },
  } = useSelector((state: any) => state.lomavisCreator);
  return (
    <div className="border border-secondary px-2 py-2 rounded">
      <AdditionalOptionsFirstComment
        platform={platform}
      ></AdditionalOptionsFirstComment>
      <CreatorVerticalSeparator />
      <label htmlFor="youtube-title">
        {t("lomavisCreator.additionalOptions.youtube_title")}
      </label>
      <input
        id="youtube-title"
        type="text"
        value={youtube_title}
        className="form-control form-control-sm"
        maxLength={100}
        onChange={(e) => {
          dispatch(
            setPostDataAttribute({
              platform: YOUTUBE,
              attribute: "youtube_title",
              value: e.target.value,
            })
          );
        }}
      />
      <div className="mt-2" />
      <label htmlFor="youtube-tags">
        {t("lomavisCreator.additionalOptions.youtube_tags")}
      </label>
      <input
        id="youtube-tags"
        type="text"
        className="form-control form-control-sm"
        onChange={(e) => {
          dispatch(
            setPostDataAttribute({
              platform: YOUTUBE,
              attribute: "youtube_tags",
              value: e.target.value,
            })
          );
        }}
      />
      <div className="mt-2" />
      <label htmlFor="youtube-category-select">
        {t("lomavisCreator.additionalOptions.youtube_category")}
      </label>
      <Form.Select id="youtube-category-select" size="sm" defaultValue={null}>
        {YOUTUBE_CATEGORY_CHOICES.map((choice) => (
          <option
            key={choice.value}
            value={choice.value}
            onClick={() => {
              dispatch(
                setPostDataAttribute({
                  platform: YOUTUBE,
                  attribute: "youtube_category",
                  value: choice.value,
                })
              );
            }}
          >
            {t(
              `lomavisCreator.additionalOptions.youtube_category_${choice.label}`
            )}
          </option>
        ))}
      </Form.Select>
      <div className="mt-2" />
      <label htmlFor="youtube-available-for-kids">
        {t("lomavisCreator.additionalOptions.youtube_available_for_kids")}
      </label>
      <Form.Select
        id="youtube-available-for-kids"
        size="sm"
        defaultValue={null}
      >
        {YOUTUBE_AVAILABLE_FOR_KIDS_CHOICES.map((choice) => (
          <option
            key={choice.value}
            value={choice.value}
            onClick={() => {
              dispatch(
                setPostDataAttribute({
                  platform: YOUTUBE,
                  attribute: "youtube_available_for_kids",
                  value: choice.value,
                })
              );
            }}
          >
            {t(
              `lomavisCreator.additionalOptions.youtube_available_for_kids_${choice.label}`
            )}
          </option>
        ))}
      </Form.Select>
      <div className="mt-2" />
      <label htmlFor="youtube-privacy-status">
        {t("lomavisCreator.additionalOptions.youtube_privacy_status")}
      </label>
      <Form.Select id="youtube-privacy-status" size="sm" defaultValue={null}>
        {YOUTUBE_PRIVACY_STATUS_CHOICES.map((choice) => (
          <option
            key={choice.value}
            value={choice.value}
            onClick={() => {
              dispatch(
                setPostDataAttribute({
                  platform: YOUTUBE,
                  attribute: "youtube_privacy_status",
                  value: choice.value,
                })
              );
            }}
          >
            {t(
              `lomavisCreator.additionalOptions.youtube_privacy_status_${choice.label}`
            )}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};
