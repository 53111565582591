import {useTranslate} from "@tolgee/react";
import FirstCommentQuillEditor from "../../creatorTextEditor/firstCommentQuillEditor";

export const AdditionalOptionsFirstComment: React.FC<any> = ({
                                                             platform,
                                                         }) => {
    const {t} = useTranslate();
    return (
        <>
            <div>
                <span><i className={"fa-duotone fa-solid fa-comment me-2"}></i></span>
                <span>{t("lomavisCreator.additionalOptions.firstComment")}</span>
            </div>
            <FirstCommentQuillEditor platform={platform}/>
        </>
    );
};
