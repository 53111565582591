import {
  COMMENTS,
  FACEBOOK,
  FACEBOOK_GROUP,
  GOOGLE,
  IMPRESSIONS,
  INSTAGRAM,
  LINKEDIN_BUSINESS_PAGE,
  LINKEDIN_PERSONAL_ACCOUNT,
  REACTIONS,
  SHARES,
  TWITTER,
  VIDEO_VIEWS
} from './constants';

export const POST_READY = 4;
export const METRIC_FACEBOOK_CUMULATED_IMPRESSIONS = 100001;
export const METRIC_FACEBOOK_CUMULATED_REACTIONS = 100002;
export const METRIC_FACEBOOK_CUMULATED_SHARES = 100003;
export const METRIC_FACEBOOK_CUMULATED_COMMENTS = 100004;
export const METRIC_FACEBOOK_CUMULATED_VIDEO_VIEWS = 100005;

export const METRIC_INSTAGRAM_CUMULATED_IMPRESSIONS = 100006;
export const METRIC_INSTAGRAM_CUMULATED_REACTIONS = 100007;
export const METRIC_INSTAGRAM_CUMULATED_SHARES = 100008;
export const METRIC_INSTAGRAM_CUMULATED_COMMENTS = 100009;
export const METRIC_INSTAGRAM_CUMULATED_VIDEO_VIEWS = 100010;

export const METRIC_GOOGLE_CUMULATED_IMPRESSIONS = 100011;
export const METRIC_GOOGLE_CUMULATED_REACTIONS = 100012;
export const METRIC_GOOGLE_CUMULATED_SHARES = 100013;
export const METRIC_GOOGLE_CUMULATED_COMMENTS = 100014;
export const METRIC_GOOGLE_CUMULATED_VIDEO_VIEWS = 100015;

export const METRIC_TWITTER_CUMULATED_IMPRESSIONS = 100016;
export const METRIC_TWITTER_CUMULATED_REACTIONS = 100017;
export const METRIC_TWITTER_CUMULATED_SHARES = 100018;
export const METRIC_TWITTER_CUMULATED_COMMENTS = 100019;
export const METRIC_TWITTER_CUMULATED_VIDEO_VIEWS = 100020;

export const METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_IMPRESSIONS = 100021;
export const METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_REACTIONS = 100022;
export const METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_SHARES = 100023;
export const METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_COMMENTS = 100024;
export const METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_VIDEO_VIEWS = 100025;

export const METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_IMPRESSIONS = 100022;
export const METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_REACTIONS = 100023;
export const METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_SHARES = 100024;
export const METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_COMMENTS = 100025;
export const METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_VIDEO_VIEWS = 100026;

export const METRIC_LINKEDIN_BUSINESS_PAGE_TOTAL_LIKES = 600;

export const METRIC_LINKEDIN_BUSINESS_PAGE_ALL_PAGE_VIEWS_PAGE_VIEWS = 6013;
export const METRIC_LINKEDIN_BUSINESS_PAGE_ALL_PAGE_VIEWS_UNIQUE_PAGE_VIEWS = 6014;

export const METRIC_LINKEDIN_BUSINESS_PAGE_ALL_DESKTOP_PAGE_VIEWS_PAGE_VIEWS = 6010;
export const METRIC_LINKEDIN_BUSINESS_PAGE_ALL_MOBILE_PAGE_VIEWS_PAGE_VIEWS = 6012;

export const METRIC_LINKEDIN_SHARE_IMPRESSION_COUNT = 513;

export const METRIC_ALL_IMPRESSIONS = 101000;
export const METRIC_ALL_REACTIONS = 101001;
export const METRIC_ALL_SHARES = 101002;
export const METRIC_ALL_VIEWS = 101003;
export const METRIC_ALL_COMMENTS = 101004;

export const METRIC_FACEBOOK_PAGE_FANS = 1;
export const METRIC_FACEBOOK_PAGE_IMPRESSIONS = 2;

export const METRIC_GMB_VIEWS_MAPS = 8;
export const METRIC_GMB_VIEWS_SEARCH = 9;

export const METRIC_GOOGLE_GMB_QUERIES_DIRECT = 5;
export const METRIC_GOOGLE_GMB_QUERIES_INDIRECT = 6;
export const METRIC_GOOGLE_GMB_QUERIES_CHAIN = 7;
export const METRIC_GOOGLE_GMB_VIEWS_MAPS = 8;
export const METRIC_GOOGLE_GMB_VIEWS_SEARCH = 9;
export const METRIC_GOOGLE_GMB_ACTIONS_WEBSITE = 10;
export const METRIC_GOOGLE_GMB_ACTIONS_PHONE = 11;
export const METRIC_GOOGLE_GMB_ACTIONS_DRIVING_DIRECTIONS = 12;
export const METRIC_GOOGLE_GMB_PHOTOS_VIEWS_MERCHANT = 13;
export const METRIC_GMB_LOCATION_AVERAGE_RATING = 19;
export const METRIC_GMB_LOCATION_RATING_COUNT = 20;

export const METRIC_INSTAGRAM_AUDIENCE_CITY = 200;
export const METRIC_INSTAGRAM_AUDIENCE_COUNTRY = 201;
export const METRIC_INSTAGRAM_AUDIENCE_GENDER_AGE = 202;
export const METRIC_INSTAGRAM_AUDIENCE_LOCALE = 203;
export const METRIC_INSTAGRAM_EMAIL_CONTACTS = 204;
export const METRIC_INSTAGRAM_FOLLOWER_COUNT = 205;
export const METRIC_INSTAGRAM_GET_DIRECTION_CLICKS = 206;
export const METRIC_INSTAGRAM_IMPRESSIONS = 207;
export const METRIC_INSTAGRAM_ENGAGEMENT = 208;
export const METRIC_INSTAGRAM_ONLINE_FOLLOWERS = 209;
export const METRIC_INSTAGRAM_PHONE_CALL_CLICKS = 210;
export const METRIC_INSTAGRAM_PROFILE_VIEWS = 211;
export const METRIC_INSTAGRAM_REACH = 212;
export const METRIC_INSTAGRAM_TEXT_MESSAGE_CLICK = 213;
export const METRIC_INSTAGRAM_WEBSITE_CLICKS = 214;
export const METRIC_INSTAGRAM_SAVED = 215;
export const METRIC_INSTAGRAM_VIDEO_VIEWS = 216;
export const METRIC_INSTAGRAM_LIKE_COUNT = 217;
export const METRIC_INSTAGRAM_COMMENTS_COUNT = 218;
export const METRIC_INSTAGRAM_REEL_PLAYS = 219;
export const METRIC_INSTAGRAM_REEL_REACH = 220;
export const METRIC_INSTAGRAM_REEL_SAVED = 221;
export const METRIC_INSTAGRAM_REEL_SHARES = 222;
export const METRIC_INSTAGRAM_REEL_TOTAL_INTERACTIONS = 223;

export const METRIC_TWITTER_POST_FAVORITE_COUNT = 400;
export const METRIC_TWITTER_POST_RETWEET_COUNT = 401;
export const METRIC_TWITTER_ACCOUNT_FAVORITE_COUNT = 410;
export const METRIC_TWITTER_ACCOUNT_FOLLOWERS_COUNT = 411;
export const METRIC_TWITTER_ACCOUNT_FRIENDS_COUNT = 412;
export const METRIC_TWITTER_ACCOUNT_LISTED_COUNT = 413;
export const METRIC_TWITTER_ACCOUNT_STATUSES_COUNT = 414;

export const METRIC_GMB_LOCAL_POST_VIEWS_SEARCH = 17;
export const METRIC_FACEBOOK_IMAGE_POST_IMPRESSIONS_PAID = 39;
const METRIC_FACEBOOK_IMAGE_POST_REACTIONS_LIKE_TOTAL = 71;
const METRIC_FACEBOOK_IMAGE_POST_REACTIONS_LOVE_TOTAL = 72;
const METRIC_FACEBOOK_IMAGE_POST_REACTIONS_WOW_TOTAL = 73;
const METRIC_FACEBOOK_IMAGE_POST_REACTIONS_HAHA_TOTAL = 74;
const METRIC_FACEBOOK_IMAGE_POST_REACTIONS_SORRY_TOTAL = 75;
const METRIC_FACEBOOK_IMAGE_POST_REACTIONS_ANGER_TOTAL = 76;
const METRIC_FACEBOOK_VIDEO_TOTAL_VIDEO_VIEWS = 78;
export const METRIC_FACEBOOK_VIDEO_TOTAL_VIDEO_VIEW_TOTAL_IMPRESSIONS = 108;
export const METRIC_FACEBOOK_VIDEO_TOTAL_VIDEO_VIEW_TOTAL_IMPRESSIONS_PAID = 111;
export const METRIC_FACEBOOK_PAGE_TOTAL_PAGE_VIEWS = 1005;
export const METRIC_FACEBOOK_PAGE_CTA_CLICKS_LOGGED_IN_TOTAL = 1012;
export const METRIC_FACEBOOK_PAGE_WEBSITE_CLICKS_LOGGED_IN_UNIQUE = 1028;
export const METRIC_FACEBOOK_VIDEO_TOTAL_VIDEO_VIEW_TOTAL_IMPRESSIONS_UNIQUE = 109;
export const METRIC_FACEBOOK_VIDEO_TOTAL_VIDEO_TOTAL_IMPRESSIONS_ORGANIC = 113;
export const METRIC_FACEBOOK_IMAGE_POST_IMPRESSIONS_UNIQUE = 34;

export const METRIC_FACEBOOK_VIDEO_POST_REACTIONS_LIKE_TOTAL = 120;
export const METRIC_FACEBOOK_VIDEO_POST_REACTIONS_LOVE_TOTAL = 121;
export const METRIC_FACEBOOK_VIDEO_POST_REACTIONS_WOW_TOTAL = 122;
export const METRIC_FACEBOOK_VIDEO_POST_REACTIONS_HAHA_TOTAL = 123;
export const METRIC_FACEBOOK_VIDEO_POST_REACTIONS_SORRY_TOTAL = 124;
export const METRIC_FACEBOOK_VIDEO_POST_REACTIONS_ANGER_TOTAL = 125;
export const METRIC_FACEBOOK_IMAGE_POST_COMMENT_COUNT = 127;
export const METRIC_FACEBOOK_IMAGE_POST_SHARES = 126;
export const METRIC_FACEBOOK_IMAGE_POST_CLICKS = 60;
export const METRIC_FACEBOOK_IMAGE_POST_IMPRESSIONS = 33;
export const METRIC_FACEBOOK_IMAGE_POST_ENGAGED_USERS = 48;
export const METRIC_FACEBOOK_IMAGE_POST_IMPRESSIONS_ORGANIC = 41;

export const METRIC_FACEBOOK_GROUP_POST_COMMENT_COUNT = 1100;
export const METRIC_FACEBOOK_GROUP_POST_SHARE_COUNT = 1101;
export const METRIC_FACEBOOK_GROUP_POST_REACTIONS_COUNT = 1102;

const METRIC_LINKEDIN_AGGREGATED_TOTAL_COMMENTS = 501;
const METRIC_LINKEDIN_REACTIONS_LIKE = 504;
const METRIC_LINKEDIN_REACTIONS_PRAISE = 505;
const METRIC_LINKEDIN_REACTIONS_MAYBE = 506;
const METRIC_LINKEDIN_REACTIONS_EMPATHY = 507;
const METRIC_LINKEDIN_REACTIONS_INTEREST = 508;
const METRIC_LINKEDIN_REACTIONS_APPRECIATION = 509;
const METRIC_LINKEDIN_SHARE_COUNT = 510;
const METRIC_LINKEDIN_VIDEO_VIDEO_VIEW = 514;
const METRIC_LINKEDIN_BUSINESS_PAGE_AGGREGATED_TOTAL_COMMENTS = 601;
const METRIC_LINKEDIN_BUSINESS_PAGE_REACTIONS_LIKE = 604;
const METRIC_LINKEDIN_BUSINESS_PAGE_REACTIONS_PRAISE = 605;
const METRIC_LINKEDIN_BUSINESS_PAGE_REACTIONS_MAYBE = 606;
const METRIC_LINKEDIN_BUSINESS_PAGE_REACTIONS_EMPATHY = 607;
const METRIC_LINKEDIN_BUSINESS_PAGE_REACTIONS_INTEREST = 608;
const METRIC_LINKEDIN_BUSINESS_PAGE_REACTIONS_APPRECIATION = 609;
const METRIC_LINKEDIN_BUSINESS_PAGE_SHARE_COUNT = 610;
const METRIC_LINKEDIN_BUSINESS_PAGE_SHARE_IMPRESSION_COUNT = 613;
const METRIC_LINKEDIN_BUSINESS_PAGE_VIDEO_VIDEO_VIEW = 614;

const METRIC_FACEBOOK_IMAGE_POST_REACTIONS_LIST = [
  METRIC_FACEBOOK_IMAGE_POST_REACTIONS_LIKE_TOTAL,
  METRIC_FACEBOOK_IMAGE_POST_REACTIONS_LOVE_TOTAL,
  METRIC_FACEBOOK_IMAGE_POST_REACTIONS_WOW_TOTAL,
  METRIC_FACEBOOK_IMAGE_POST_REACTIONS_HAHA_TOTAL,
  METRIC_FACEBOOK_IMAGE_POST_REACTIONS_SORRY_TOTAL,
  METRIC_FACEBOOK_IMAGE_POST_REACTIONS_ANGER_TOTAL
];

const METRIC_FACEBOOK_VIDEO_POST_REACTIONS = [
  METRIC_FACEBOOK_VIDEO_POST_REACTIONS_ANGER_TOTAL,
  METRIC_FACEBOOK_VIDEO_POST_REACTIONS_HAHA_TOTAL,
  METRIC_FACEBOOK_VIDEO_POST_REACTIONS_LIKE_TOTAL,
  METRIC_FACEBOOK_VIDEO_POST_REACTIONS_LOVE_TOTAL,
  METRIC_FACEBOOK_VIDEO_POST_REACTIONS_SORRY_TOTAL,
  METRIC_FACEBOOK_VIDEO_POST_REACTIONS_WOW_TOTAL
];

const ANALYTICS_LIST_FACEBOOK_IMPRESSIONS_KEYS_LIST = [
  METRIC_FACEBOOK_IMAGE_POST_IMPRESSIONS,
  METRIC_FACEBOOK_IMAGE_POST_IMPRESSIONS_PAID,
  METRIC_FACEBOOK_VIDEO_TOTAL_VIDEO_VIEW_TOTAL_IMPRESSIONS,
  METRIC_FACEBOOK_VIDEO_TOTAL_VIDEO_VIEW_TOTAL_IMPRESSIONS_PAID
];
const ANALYTICS_LIST_FACEBOOK_REACTIONS_KEYS_LIST = [
  ...METRIC_FACEBOOK_IMAGE_POST_REACTIONS_LIST,
  ...METRIC_FACEBOOK_VIDEO_POST_REACTIONS
];

const ANALYTICS_LIST_FACEBOOK_SHARES_KEYS_LIST = [
  METRIC_FACEBOOK_IMAGE_POST_SHARES
];
const ANALYTICS_LIST_FACEBOOK_COMMENTS_KEYS_LIST = [
  METRIC_FACEBOOK_IMAGE_POST_COMMENT_COUNT
];
const ANALYTICS_LIST_FACEBOOK_VIDEO_VIEWS_KEYS_LIST = [
  METRIC_FACEBOOK_VIDEO_TOTAL_VIDEO_VIEWS
];

export const ANALYTICS_LIST_INSTAGRAM_IMPRESSIONS_KEYS_LIST = [
  METRIC_INSTAGRAM_IMPRESSIONS
];
const ANALYTICS_LIST_INSTAGRAM_REACTIONS_KEYS_LIST = [
  METRIC_INSTAGRAM_LIKE_COUNT
];
const ANALYTICS_LIST_INSTAGRAM_SHARES_KEYS_LIST = [
  METRIC_INSTAGRAM_REEL_SHARES
];
const ANALYTICS_LIST_INSTAGRAM_COMMENTS_KEYS_LIST = [
  METRIC_INSTAGRAM_COMMENTS_COUNT
];
const ANALYTICS_LIST_INSTAGRAM_VIDEO_VIEWS_KEYS_LIST = [
  METRIC_INSTAGRAM_VIDEO_VIEWS,
  METRIC_INSTAGRAM_REEL_PLAYS
];

const ANALYTICS_LIST_GOOGLE_IMPRESSIONS_KEYS_LIST = [
  METRIC_GMB_LOCAL_POST_VIEWS_SEARCH
];
const ANALYTICS_LIST_GOOGLE_REACTIONS_KEYS_LIST: any = [];
const ANALYTICS_LIST_GOOGLE_SHARES_KEYS_LIST: any = [];
const ANALYTICS_LIST_GOOGLE_COMMENTS_KEYS_LIST: any = [];
const ANALYTICS_LIST_GOOGLE_VIDEO_VIEWS_KEYS_LIST: any = [];

const ANALYTICS_LIST_TWITTER_IMPRESSIONS_KEYS_LIST: any = [];
const ANALYTICS_LIST_TWITTER_REACTIONS_KEYS_LIST = [
  METRIC_TWITTER_POST_FAVORITE_COUNT
];
const ANALYTICS_LIST_TWITTER_SHARES_KEYS_LIST = [
  METRIC_TWITTER_POST_RETWEET_COUNT
];
const ANALYTICS_LIST_TWITTER_COMMENTS_KEYS_LIST: any = [];
const ANALYTICS_LIST_TWITTER_VIDEO_VIEWS_KEYS_LIST: any = [];

const ANALYTICS_LIST_LINKEDIN_PERSONAL_ACCOUNT_IMPRESSIONS_KEYS_LIST: any = [];
const ANALYTICS_LIST_LINKEDIN_PERSONAL_ACCOUNT_REACTIONS_KEYS_LIST = [
  METRIC_LINKEDIN_REACTIONS_LIKE,
  METRIC_LINKEDIN_REACTIONS_PRAISE,
  METRIC_LINKEDIN_REACTIONS_MAYBE,
  METRIC_LINKEDIN_REACTIONS_EMPATHY,
  METRIC_LINKEDIN_REACTIONS_INTEREST,
  METRIC_LINKEDIN_REACTIONS_APPRECIATION
];
const ANALYTICS_LIST_LINKEDIN_PERSONAL_ACCOUNT_SHARES_KEYS_LIST = [
  METRIC_LINKEDIN_SHARE_COUNT
];
const ANALYTICS_LIST_LINKEDIN_PERSONAL_ACCOUNT_COMMENTS_KEYS_LIST = [
  METRIC_LINKEDIN_AGGREGATED_TOTAL_COMMENTS
];
const ANALYTICS_LIST_LINKEDIN_PERSONAL_ACCOUNT_VIDEO_VIEWS_KEYS_LIST = [
  METRIC_LINKEDIN_VIDEO_VIDEO_VIEW
];

const ANALYTICS_LIST_LINKEDIN_BUSINESS_PAGE_IMPRESSIONS_KEYS_LIST = [
  METRIC_LINKEDIN_BUSINESS_PAGE_SHARE_IMPRESSION_COUNT
];
const ANALYTICS_LIST_LINKEDIN_BUSINESS_PAGE_REACTIONS_KEYS_LIST = [
  METRIC_LINKEDIN_BUSINESS_PAGE_REACTIONS_LIKE,
  METRIC_LINKEDIN_BUSINESS_PAGE_REACTIONS_PRAISE,
  METRIC_LINKEDIN_BUSINESS_PAGE_REACTIONS_MAYBE,
  METRIC_LINKEDIN_BUSINESS_PAGE_REACTIONS_EMPATHY,
  METRIC_LINKEDIN_BUSINESS_PAGE_REACTIONS_INTEREST,
  METRIC_LINKEDIN_BUSINESS_PAGE_REACTIONS_APPRECIATION
];
const ANALYTICS_LIST_LINKEDIN_BUSINESS_PAGE_SHARES_KEYS_LIST = [
  METRIC_LINKEDIN_BUSINESS_PAGE_SHARE_COUNT
];
const ANALYTICS_LIST_LINKEDIN_BUSINESS_PAGE_COMMENTS_KEYS_LIST = [
  METRIC_LINKEDIN_BUSINESS_PAGE_AGGREGATED_TOTAL_COMMENTS
];
const ANALYTICS_LIST_LINKEDIN_BUSINESS_PAGE_VIDEO_VIEWS_KEYS_LIST = [
  METRIC_LINKEDIN_BUSINESS_PAGE_VIDEO_VIDEO_VIEW
];

export const CUMULATED_POST_METRIC_CONFIG = {
  [FACEBOOK]: {
    [METRIC_FACEBOOK_CUMULATED_IMPRESSIONS]:
      ANALYTICS_LIST_FACEBOOK_IMPRESSIONS_KEYS_LIST,
    [METRIC_FACEBOOK_CUMULATED_REACTIONS]:
      ANALYTICS_LIST_FACEBOOK_REACTIONS_KEYS_LIST,
    [METRIC_FACEBOOK_CUMULATED_SHARES]:
      ANALYTICS_LIST_FACEBOOK_SHARES_KEYS_LIST,
    [METRIC_FACEBOOK_CUMULATED_COMMENTS]:
      ANALYTICS_LIST_FACEBOOK_COMMENTS_KEYS_LIST,
    [METRIC_FACEBOOK_CUMULATED_VIDEO_VIEWS]:
      ANALYTICS_LIST_FACEBOOK_VIDEO_VIEWS_KEYS_LIST
  },
  [INSTAGRAM]: {
    [METRIC_INSTAGRAM_CUMULATED_IMPRESSIONS]:
      ANALYTICS_LIST_INSTAGRAM_IMPRESSIONS_KEYS_LIST,
    [METRIC_INSTAGRAM_CUMULATED_REACTIONS]:
      ANALYTICS_LIST_INSTAGRAM_REACTIONS_KEYS_LIST,
    [METRIC_INSTAGRAM_CUMULATED_SHARES]:
      ANALYTICS_LIST_INSTAGRAM_SHARES_KEYS_LIST,
    [METRIC_INSTAGRAM_CUMULATED_COMMENTS]:
      ANALYTICS_LIST_INSTAGRAM_COMMENTS_KEYS_LIST,
    [METRIC_INSTAGRAM_CUMULATED_VIDEO_VIEWS]:
      ANALYTICS_LIST_INSTAGRAM_VIDEO_VIEWS_KEYS_LIST
  },
  [GOOGLE]: {
    [METRIC_GOOGLE_CUMULATED_IMPRESSIONS]:
      ANALYTICS_LIST_GOOGLE_IMPRESSIONS_KEYS_LIST,
    [METRIC_GOOGLE_CUMULATED_REACTIONS]:
      ANALYTICS_LIST_GOOGLE_REACTIONS_KEYS_LIST,
    [METRIC_GOOGLE_CUMULATED_SHARES]: ANALYTICS_LIST_GOOGLE_SHARES_KEYS_LIST,
    [METRIC_GOOGLE_CUMULATED_COMMENTS]:
      ANALYTICS_LIST_GOOGLE_COMMENTS_KEYS_LIST,
    [METRIC_GOOGLE_CUMULATED_VIDEO_VIEWS]:
      ANALYTICS_LIST_GOOGLE_VIDEO_VIEWS_KEYS_LIST
  },
  [TWITTER]: {
    [METRIC_TWITTER_CUMULATED_IMPRESSIONS]:
      ANALYTICS_LIST_TWITTER_IMPRESSIONS_KEYS_LIST,
    [METRIC_TWITTER_CUMULATED_REACTIONS]:
      ANALYTICS_LIST_TWITTER_REACTIONS_KEYS_LIST,
    [METRIC_TWITTER_CUMULATED_SHARES]: ANALYTICS_LIST_TWITTER_SHARES_KEYS_LIST,
    [METRIC_TWITTER_CUMULATED_COMMENTS]:
      ANALYTICS_LIST_TWITTER_COMMENTS_KEYS_LIST,
    [METRIC_TWITTER_CUMULATED_VIDEO_VIEWS]:
      ANALYTICS_LIST_TWITTER_VIDEO_VIEWS_KEYS_LIST
  },
  [LINKEDIN_PERSONAL_ACCOUNT]: {
    [METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_IMPRESSIONS]:
      ANALYTICS_LIST_LINKEDIN_PERSONAL_ACCOUNT_IMPRESSIONS_KEYS_LIST,

    [METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_REACTIONS]:
      ANALYTICS_LIST_LINKEDIN_PERSONAL_ACCOUNT_REACTIONS_KEYS_LIST,

    [METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_SHARES]:
      ANALYTICS_LIST_LINKEDIN_PERSONAL_ACCOUNT_SHARES_KEYS_LIST,

    [METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_COMMENTS]:
      ANALYTICS_LIST_LINKEDIN_PERSONAL_ACCOUNT_COMMENTS_KEYS_LIST,

    [METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_VIDEO_VIEWS]:
      ANALYTICS_LIST_LINKEDIN_PERSONAL_ACCOUNT_VIDEO_VIEWS_KEYS_LIST
  },
  [LINKEDIN_BUSINESS_PAGE]: {
    [METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_IMPRESSIONS]:
      ANALYTICS_LIST_LINKEDIN_BUSINESS_PAGE_IMPRESSIONS_KEYS_LIST,

    [METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_REACTIONS]:
      ANALYTICS_LIST_LINKEDIN_BUSINESS_PAGE_REACTIONS_KEYS_LIST,

    [METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_SHARES]:
      ANALYTICS_LIST_LINKEDIN_BUSINESS_PAGE_SHARES_KEYS_LIST,
    [METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_COMMENTS]:
      ANALYTICS_LIST_LINKEDIN_BUSINESS_PAGE_COMMENTS_KEYS_LIST,
    [METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_VIDEO_VIEWS]:
      ANALYTICS_LIST_LINKEDIN_BUSINESS_PAGE_VIDEO_VIEWS_KEYS_LIST
  }
};

export const SHORT_ANALYTICS_TABLE_CONFIG = {
  [VIDEO_VIEWS]: {
    [FACEBOOK]: METRIC_FACEBOOK_CUMULATED_VIDEO_VIEWS,
    [INSTAGRAM]: METRIC_INSTAGRAM_CUMULATED_VIDEO_VIEWS,
    [TWITTER]: METRIC_TWITTER_CUMULATED_VIDEO_VIEWS,
    [LINKEDIN_BUSINESS_PAGE]:
      METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_VIDEO_VIEWS,
    [LINKEDIN_PERSONAL_ACCOUNT]:
      METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_VIDEO_VIEWS,
    [GOOGLE]: METRIC_GOOGLE_CUMULATED_VIDEO_VIEWS
  },
  [IMPRESSIONS]: {
    [FACEBOOK]: METRIC_FACEBOOK_CUMULATED_IMPRESSIONS,
    [INSTAGRAM]: METRIC_INSTAGRAM_CUMULATED_IMPRESSIONS,
    [TWITTER]: METRIC_TWITTER_CUMULATED_IMPRESSIONS,
    [LINKEDIN_BUSINESS_PAGE]:
      METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_IMPRESSIONS,
    [LINKEDIN_PERSONAL_ACCOUNT]:
      METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_IMPRESSIONS,
    [GOOGLE]: METRIC_GOOGLE_CUMULATED_IMPRESSIONS
  },
  [REACTIONS]: {
    [FACEBOOK_GROUP]: METRIC_FACEBOOK_GROUP_POST_REACTIONS_COUNT,
    [FACEBOOK]: METRIC_FACEBOOK_CUMULATED_REACTIONS,
    [INSTAGRAM]: METRIC_INSTAGRAM_CUMULATED_REACTIONS,
    [TWITTER]: METRIC_TWITTER_CUMULATED_REACTIONS,
    [LINKEDIN_BUSINESS_PAGE]: METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_REACTIONS,
    [LINKEDIN_PERSONAL_ACCOUNT]:
      METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_REACTIONS,
    [GOOGLE]: METRIC_GOOGLE_CUMULATED_REACTIONS
  },
  [SHARES]: {
    [FACEBOOK_GROUP]: METRIC_FACEBOOK_GROUP_POST_SHARE_COUNT,
    [FACEBOOK]: METRIC_FACEBOOK_CUMULATED_SHARES,
    [INSTAGRAM]: METRIC_INSTAGRAM_CUMULATED_SHARES,
    [TWITTER]: METRIC_TWITTER_CUMULATED_SHARES,
    [LINKEDIN_BUSINESS_PAGE]: METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_SHARES,
    [LINKEDIN_PERSONAL_ACCOUNT]:
      METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_SHARES,
    [GOOGLE]: METRIC_GOOGLE_CUMULATED_SHARES
  },
  [COMMENTS]: {
    [FACEBOOK_GROUP]: METRIC_FACEBOOK_GROUP_POST_COMMENT_COUNT,
    [FACEBOOK]: METRIC_FACEBOOK_CUMULATED_COMMENTS,
    [INSTAGRAM]: METRIC_INSTAGRAM_CUMULATED_COMMENTS,
    [TWITTER]: METRIC_TWITTER_CUMULATED_COMMENTS,
    [LINKEDIN_BUSINESS_PAGE]: METRIC_LINKEDIN_BUSINESS_PAGE_CUMULATED_COMMENTS,
    [LINKEDIN_PERSONAL_ACCOUNT]:
      METRIC_LINKEDIN_PERSONAL_ACCOUNT_CUMULATED_COMMENTS,
    [GOOGLE]: METRIC_GOOGLE_CUMULATED_COMMENTS
  }
};
export const SHORT_ANALYTICS_TABLE_DEMO_DATA_RANGES_CONFIG = {
  [VIDEO_VIEWS]: {
    range: [12, 500]
  },
  [IMPRESSIONS]: {
    range: [152, 497]
  },
  [REACTIONS]: {
    range: [12, 78]
  },
  [SHARES]: {
    range: [12, 78]
  },
  [COMMENTS]: {
    range: [12, 78]
  }
};

export const SHORT_ANALYTICS_TABLE_DEMO_DATA_PLOT_CONFIG = {
  [VIDEO_VIEWS]: {
    range: [12, 15]
  },
  [IMPRESSIONS]: {
    range: [152, 155]
  },
  [REACTIONS]: {
    range: [12, 15]
  },
  [SHARES]: {
    range: [12, 15]
  },
  [COMMENTS]: {
    range: [12, 15]
  }
};

export const SHORT_ANALYTICS_PLOTS = [
  { title: IMPRESSIONS, metric: METRIC_ALL_IMPRESSIONS },
  { title: REACTIONS, metric: METRIC_ALL_REACTIONS },
  { title: SHARES, metric: METRIC_ALL_SHARES },
  { title: COMMENTS, metric: METRIC_ALL_COMMENTS }
];

export const PLATFORMS = [
  FACEBOOK,
  FACEBOOK_GROUP,
  INSTAGRAM,
  TWITTER,
  LINKEDIN_BUSINESS_PAGE,
  LINKEDIN_PERSONAL_ACCOUNT,
  GOOGLE
];
