import { useTranslate } from "@tolgee/react";
import { INSTAGRAM } from "../../../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  addIGCollaborator,
  deleteACollaborator,
  deleteATag,
  setModalUiContent,
} from "../../../../redux/slices/lomavisCreator/lomavisCreator";

import { TypeaheadLocationSelection } from "./locationSelectionInput";
import ModalBody from "../../../modals/modalBody";
import IGTags from "../modals/InstagramTags";
import { lomavisCreatorRootState } from "../../../../pages/lomavisCreator";
import { Tooltip } from "bootstrap";
import toast from "react-hot-toast";
import { CreatorVerticalSeparator } from "../include/VerticalSeperator";
import { AdditionalOptionsFirstComment } from "./includes/FirstComment";

export const InstagramAdditionalOptions: React.FC<any> = ({
  platform = INSTAGRAM,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const textRef = useRef(null);
  const [addTagToggle, setAddTagToggle] = useState(false);
  const [addNewCollaborator, setAddNewCollaborator] = useState(false);

  const [collaboratorText, setCollaboratorText] = useState("");

  const instagramCollaborators = useSelector(
    (state: lomavisCreatorRootState) =>
      state.lomavisCreator.postData[INSTAGRAM]?.instagram_collaborators
  );
  const instagramMedia = useSelector(
    (state: lomavisCreatorRootState) =>
      state.lomavisCreator.postData[INSTAGRAM]?.media
  );

  const handleTextChange = (e) => {
    setCollaboratorText(e.target.innerText.trim());
  };

  const handleSaveClick = () => {
    const cleanedText = collaboratorText.trim();

    if (
      cleanedText &&
      instagramCollaborators.length < 3 &&
      !instagramCollaborators.some((collab) => collab.username === cleanedText)
    ) {
      dispatch(
        addIGCollaborator({
          newCollaborator: { username: cleanedText },
        })
      );
      setCollaboratorText("");
      if (textRef.current) {
        textRef.current.innerText = "";
      }
    } else if (
      instagramCollaborators.some((collab) => collab.username === cleanedText)
    ) {
      toast.error(`${t("lomavisCreator.errors.Collaborator")}`, {
        duration: 4000,
        position: "top-right",
      });
    } else if (instagramCollaborators.length >= 3) {
      toast.error(`${t("lomavisCreator.errors.maxCollaborators")}`, {
        duration: 4000,
        position: "top-right",
      });
    }

    setAddNewCollaborator(false);
  };

  const instagramUserTags = useSelector(
    (state: lomavisCreatorRootState) =>
      state.lomavisCreator.postData[INSTAGRAM]?.instagram_temp_tags
  );
  const postData = useSelector(
    (state: lomavisCreatorRootState) => state.lomavisCreator.postData[INSTAGRAM]
  );
  const collaboratorTooltip = `<div class="tooltip" role="tooltip"><div class="d-flex flex-column w-200px bg-white shadow rounded">
              <div class="w-100 mh-200px px-2 py-1 overflow-auto text-muted">
                ${t("lomavisCreator.collaborator_tooltip")}
              </div>
            </div></div>'`;
  const assetCountTooltip = `<div class="tooltip" role="tooltip"><div class="d-flex flex-column w-200px bg-white shadow rounded">
              <div class="w-100 mh-200px px-2 py-1 overflow-auto">
                ${t("lomavisCreator.assetCount_tooltip")}
              </div>
            </div></div>'`;

  useEffect(() => {
    const collaboratorTooltipTrigger = document.querySelector(
      ".collaborator-tooltip-trigger"
    );
    const assetCountTooltipTrigger = document.querySelector(
      ".assetCount-tooltip-trigger"
    );

    collaboratorTooltipTrigger &&
      new Tooltip(collaboratorTooltipTrigger, {
        title: "title",
        placement: "auto",
        trigger: "hover",
        container: "body",
        template: collaboratorTooltip,
      });
    assetCountTooltipTrigger &&
      new Tooltip(assetCountTooltipTrigger, {
        title: "title",
        placement: "auto",
        trigger: "hover",
        container: "body",
        template: assetCountTooltip,
      });
  }, []);
  useEffect(() => {
    if (textRef.current) {
      textRef.current.focus();
    }
  }, [addNewCollaborator]);

  return (
    <div className="border border-secondary px-2 py-2 rounded">
      <AdditionalOptionsFirstComment
        platform={platform}
      ></AdditionalOptionsFirstComment>

      <CreatorVerticalSeparator />

      <TypeaheadLocationSelection
        platform={platform}
        name_key="instagram_location_name"
        id_key="instagram_location_id"
      />

      <CreatorVerticalSeparator />

      <div className="mt-2 d-flex flex-column gap-2">
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center mb-2">
            <i className="fa-duotone fa-user me-2"></i>
            <span>{t("lomavisCreator.additionalOptions.taggedUsers")}</span>
          </div>
          <div className="d-flex flex-column gap-1 align-items-start justify-content-center">
            <div className="d-flex flex-column gap-1">
              {instagramUserTags.length > 0 &&
                instagramUserTags?.map((item, index) => {
                  const imageIndex = instagramMedia.findIndex(
                    (obj) => obj?.uuid === item?.uuid
                  );
                  if (item.tags?.length > 0 && imageIndex !== -1) {
                    return (
                      <div className="d-flex gap-1">
                        <div>
                          <div className="d-flex flex-nowrap text-black text-nowrap gap-1 badge bg-secondary">
                            <span className="text-black ">
                              {" "}
                              {t("lomavisCreator.IG.taggedUsers-image")}{" "}
                            </span>
                            <span></span>
                            {imageIndex + 1}
                          </div>
                        </div>
                        :
                        <div className="d-flex gap-1 flex-wrap">
                          {item?.tags?.map((tags, index) => (
                            <div className="badge bg-secondary">
                              <span className="text-black tagNameSpan">
                                {tags?.text}
                              </span>
                              <i
                                onClick={() => {
                                  dispatch(
                                    deleteATag({
                                      uuid: item.uuid,
                                      tagIndex: index,
                                    })
                                  );
                                }}
                                className="fa-solid fa-xmark icon-xs text-muted float-end ms-3 pe-1 cursor-pointer closeBtns"
                              ></i>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  }
                })}
            </div>

            <div className="badge bg-secondary cursor-pointer d-flex flex-row align-items-center">
              <span>
                <i className="fa-duotone fa-plus f me-2 text-dark"></i>
              </span>
              <span
                onClick={() => {
                  instagramMedia?.length > 0
                    ? dispatch(
                        setModalUiContent({
                          modal: "IGTags",
                          attribute: "toggle",
                          value: true,
                        })
                      )
                    : toast.error(` ${t("lomavisCreator.IGPostmedia.Empty")}`, {
                        duration: 4000,
                        position: "top-right",
                      });
                }}
                className="text-dark cursor-pointer"
              >
                {t("lomavisCreator.additionalOptions.tagUsers")}
              </span>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-2">
          <div className="d-flex align-items-center gap-1">
            <i className="fa-duotone fa-users me-2"></i>
            <span>{t("lomavisCreator.additionalOptions.add_new_collabs")}</span>

            <i
              style={{ cursor: "pointer" }}
              className="fa-duotone fa-circle-info collaborator-tooltip-trigger"
              data-bs-toggle="tooltip"
            ></i>
          </div>

          <div className="">
            {addNewCollaborator ? (
              <div className="d-flex cursor-pointer">
                <div
                  id="addInstagramCollaboratorField"
                  className="d-flex border border-1 border-secondary align-items-center bg-white text-dark px-1 rounded min-w-150px me-2"
                  spellCheck={false}
                  contentEditable={false}
                >
                  <div
                    className="min-w-150px"
                    id="collaboratorFieldText"
                    contentEditable="true"
                    ref={textRef}
                    onInput={handleTextChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" && collaboratorText) {
                        event.preventDefault();
                        handleSaveClick();
                      }
                    }}
                  ></div>
                  <i
                    onClick={() => handleSaveClick()}
                    id="saveInstagramCollaboratorBtn"
                    className="fa-duotone fa-check icon-xs text-muted float-end ms-3 pe-1 cursor-pointer"
                  ></i>
                </div>{" "}
                <div
                  onClick={() => setAddNewCollaborator(false)}
                  id="instagramCollaboratorButton"
                  className="badge bg-secondary cursor-pointer d-flex flex-row align-items-center"
                >
                  <span>
                    <i
                      id="collaboratorBtnIcon"
                      className="fa-duotone f me-2 text-dark fa-minus"
                    ></i>
                  </span>
                  <span id="collaboratorBtnText" className="text-dark">
                    {t("lomavisCreator.cancel")}
                  </span>
                </div>
              </div>
            ) : (
              <div
                onClick={() => setAddNewCollaborator(true)}
                className="text-dark cursor-pointer badge bg-secondary "
              >
                {" "}
                <span>
                  <i className="fa-duotone fa-plus f me-2 text-dark"></i>
                </span>
                {t("lomavisCreator.additionalOptions.addNewCollaborator")}
              </div>
            )}
          </div>

          <div className="d-flex gap-1 flex-wrap">
            {instagramCollaborators?.map((collaborator, index) => {
              return (
                <div className="badge bg-light-success me-1">
                  <span className="text-black tagNameSpan">
                    {collaborator?.username}
                  </span>
                  <i
                    onClick={() => {
                      dispatch(
                        deleteACollaborator({
                          collabIndex: index,
                        })
                      );
                    }}
                    className="fa-solid fa-xmark icon-xs text-muted float-end ms-3 pe-1 cursor-pointer closeBtns"
                  ></i>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
