import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IsLoadingIndicator = () => {
  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      <i className="fa-spin fas fa-circle-notch" />
    </div>
  );
};

export default IsLoadingIndicator;
