import { useState } from "react";
import { useLocales } from "../../../locales";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Col, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { ANALYTICS_COLOR_8 } from "../../../config/constants";
import { toast } from "react-hot-toast";
import { abbreviateNumber } from "js-abbreviation-number";

export default function PlotButtons({ chartRef, data, config, seriesLength }) {
  const { translate: t, currentLang } = useLocales();
  const [buttonStates, setButtonStates] = useState({});
  const handleClick = (e, title) => {
    const chart = chartRef.current.getEchartsInstance();
    if (buttonStates[title]?.disabled) {
      chart.dispatchAction({ type: "legendSelect", name: title });
      setButtonStates({
        ...buttonStates,
        [title]: { disabled: false },
      });
      return;
    }
    const disabledSeriesNumber = Object.keys(buttonStates).reduce(
      (count, key) => (buttonStates[key]?.disabled ? count + 1 : count),
      0
    );
    if (seriesLength - disabledSeriesNumber === 1) {
      toast.error(t("mixedChart.at_least_one_metric_required"));
      return;
    }
    if (!buttonStates[title]?.disabled) {
      chart.dispatchAction({ type: "legendUnSelect", name: title });
      setButtonStates({
        ...buttonStates,
        [title]: { disabled: true },
      });
    }
  };
  return (
    <>
      {Object.keys(config).map((key, i) => {
        const item = data[key];
        const plotConfig = config[key] || {};
        if (!item || !plotConfig) return null;
        return (
          <Col xs={6} md={2} key={i} className="my-1">
            <PlotsButton
              item={item}
              plotConfig={plotConfig}
              buttonStates={buttonStates}
              handleClick={handleClick}
              t={t}
              currentLang={currentLang}
            />
          </Col>
        );
      })}
    </>
  );
}

const PlotsButton = ({
  item,
  plotConfig,
  buttonStates,
  handleClick,
  t,
  currentLang,
}) => {
  return (
    <button
      size="lg"
      className="w-100 shadow text-white h-100 btn  d-flex flex-column position-relative"
      style={{
        backgroundColor:
          buttonStates[plotConfig.title] &&
          buttonStates[plotConfig.title].disabled
            ? ANALYTICS_COLOR_8
            : plotConfig.hexColor,
      }}
      onClick={(e) => handleClick(e, plotConfig.title)}
    >
      <OverlayTrigger
        key={plotConfig.title}
        placement="bottom"
        overlay={<Tooltip>{t(plotConfig?.title)}</Tooltip>}
      >
        <span
          className="fs-6 align-self-start"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          {t(plotConfig.title)}
        </span>
      </OverlayTrigger>

      <div className="d-flex align-items-center ">
        <OverlayTrigger
          key={plotConfig.title}
          placement="bottom"
          overlay={
            <Tooltip>
              {item
                ? new Intl.NumberFormat(currentLang.value).format(
                    item.displayValue
                  )
                : "-"}
            </Tooltip>
          }
        >
          <span className="fs-1">
            {item?.values?.length > 0
              ? item?.displayValue >= 1000000
                ? abbreviateNumber(item?.displayValue, 2)
                : new Intl.NumberFormat(currentLang.value).format(
                    item.displayValue
                  )
              : "-"}
          </span>
        </OverlayTrigger>
        &nbsp;
        {item?.values?.length > 0 ? (
          <FontAwesomeIcon
            icon={
              item.trend < 0
                ? solid("arrow-down")
                : item.trend === 0
                ? solid("arrow-right")
                : solid("arrow-up")
            }
          />
        ) : (
          ""
        )}
      </div>
      {plotConfig?.infoMessage ? (
        <div className="position-absolute bottom-0 end-0">
          <OverlayTrigger
            key={plotConfig.title}
            placement="bottom"
            overlay={
              <Popover id="popover-contained">
                {/* <Popover.Header as="h3"> */}
                {/*  {t('mixedChart.what_does_this_mean')} */}
                {/* </Popover.Header> */}
                <Popover.Body>{t(plotConfig.infoMessage)}</Popover.Body>
              </Popover>
            }
          >
            <div className="m-1">
              <i className="fa-duotone fa-circle-info fa-sm"></i>
            </div>
          </OverlayTrigger>
        </div>
      ) : (
        ""
      )}
    </button>
  );
};
