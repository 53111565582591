/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { DateRangePicker } from 'react-date-range';
import Button from 'react-bootstrap/Button';
import { useEffect, useRef, useState } from 'react';
import { getDefaultRanges } from './utils';
import { useLocales } from '../../locales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from '../../redux/store';
interface Props {
  setDates: (dates: { startDate: Date; endDate: Date }) => void;
}

interface SelectionRange {
  startDate: Date;
  endDate: Date;
  key: string;
  active?: string;
}

// eslint-disable-next-line react/prop-types
const DatePicker: React.FC<Props> = ({ setDates }) => {
  const DEFAULT_INDEX = 0;
  const defaultRanges = getDefaultRanges();
  const [shortRange, setShortRange] = useState(false);
  const { currentLang, translate: t } = useLocales();
  const [selectionRange, setSelectionRange] = useState<SelectionRange>({
    startDate: defaultRanges[DEFAULT_INDEX].startDate,
    endDate: defaultRanges[DEFAULT_INDEX].endDate,
    key: 'selection',
    active: defaultRanges[DEFAULT_INDEX].label
  });
  const [ranges, setRanges] = useState<any[]>([]);
  useEffect(() => {
    const ranges: any[] = defaultRanges.map((item: any) => {
      return {
        label: t(item.label),
        range: () => ({
          startDate: item.startDate,
          endDate: item.endDate,
          key: item.label
        }),
        isSelected: (dateRange: any) => {
          return dateRange.active === item.label;
        }
      };
    });
    setRanges(ranges);
  }, []);

  const handleSelect = (ranges: any): void => {
    setSelectionRange({
      ...selectionRange,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      active: ranges.selection.key
    });
  };
  const [show, setShow] = useState(false);
  const [buttonMessage, setButtonMessage] = useState(
    t(defaultRanges[DEFAULT_INDEX].label)
  );
  const getLabel = (start: Date, end: Date): string => {
    const formatedStart = start.toLocaleDateString(currentLang.value);
    const formatedEnd = end.toLocaleDateString(currentLang.value);
    const match = defaultRanges.find(
      (item: SelectionRange) =>
        item.startDate.toLocaleDateString(currentLang.value) ===
          formatedStart &&
        item.endDate.toLocaleDateString(currentLang.value) === formatedEnd
    );
    return match ? t(match.label) : `${formatedStart} - ${formatedEnd}`;
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleApply = () => {
    const minRangeLength = 5;
    const diffInDays = Math.ceil(
      ((selectionRange.endDate as any) - (selectionRange.startDate as any)) /
        (1000 * 60 * 60 * 24)
    );

    // Check if the selected range meets the minimum length requirement
    if (diffInDays >= minRangeLength) {
      setShortRange(false);
      setDates({
        startDate: selectionRange.startDate,
        endDate: selectionRange.endDate
      });
      setButtonMessage(
        getLabel(selectionRange.startDate, selectionRange.endDate)
      );
      setShow(false);
    } else setShortRange(true);
  };

  const myRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const { demo } = useSelector((state) => state.locationDetails);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (
        !buttonRef.current?.contains(event.target as Node) &&
        myRef.current &&
        !myRef.current.contains(event.target as Node)
      ) {
        setShow(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [myRef]);

  return (
    <OverlayTrigger
      show={show}
      placement="bottom"
      trigger="click"
      overlay={
        <Popover className="datePickerpopover">
          <div ref={myRef}>
            <DateRangePicker
              maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
              ranges={[selectionRange]}
              staticRanges={ranges}
              onChange={handleSelect}
              months={1}
              inputRanges={[]}
              weekStartsOn={currentLang.systemValue === 'en' ? 0 : 1}
            />
            <div className="d-flex justify-content-end">
              <Button
                variant="secondary"
                size="sm"
                className="m-2 "
                onClick={() => {
                  setShow(false);
                }}
              >
                {t('cancel')}
              </Button>
              <Button
                variant="primary"
                size="sm"
                className="m-2"
                onClick={handleApply}
              >
                {t('apply')}
              </Button>
            </div>{' '}
            {demo && (
              <div className="d-flex justify-content-center alert-warning align-items-center py-2 text-warning fw-bolder">
                {t('its_not_possible_to_change_date_range_on_demo_location')}
              </div>
            )}
            {shortRange && (
              <div className="d-flex justify-content-center alert-warning align-items-center py-2 text-warning fw-bolder">
                {t('range_should_at_least_be_5_days_long')}
              </div>
            )}
          </div>
        </Popover>
      }
    >
      <div ref={buttonRef}>
        <Button
          variant="light"
          size="sm"
          onClick={() => {
            setShow(!show);
          }}
          className="fs-7 d-flex align-items-center bg-white h-100 shadow w-100"
        >
          <FontAwesomeIcon
            icon={faCalendarDays}
            size="1x"
            className=" text-dark"
          />
          <span className="mx-1 text-dark"> {buttonMessage}</span>&nbsp;
        </Button>
      </div>
    </OverlayTrigger>
  );
};

export default DatePicker;
