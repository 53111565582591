import { useTranslate } from "@tolgee/react";
import { GOOGLE } from "../../../../config/constants";
import { Dropdown, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPostDataAttribute } from "../../../../redux/slices/lomavisCreator/lomavisCreator";
import {
  GOOGLE_CTA_TYPE_CHOICE_CALL,
  GOOGLE_CTA_TYPE_CHOICES,
} from "../contants";
import { CreatorVerticalSeparator } from "../include/VerticalSeperator";

export const GoogleAdditionalOptions: React.FC<any> = ({
  platform = GOOGLE,
}) => {
  const { t } = useTranslate();
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const {
    postData: {
      [GOOGLE]: { google_cta_type, google_cta_url },
    },
  } = useSelector((state: any) => state.lomavisCreator);
  const [showUrlInput, setShowUrlInput] = useState(false);
  useEffect(() => {
    console.log("google_cta_type", google_cta_type);
    setShowUrlInput(
      google_cta_type && google_cta_type != GOOGLE_CTA_TYPE_CHOICE_CALL
    );
  }, [google_cta_type]);
  return (
    <div className="border border-secondary px-2 py-2 rounded">
      <label htmlFor="google-type-select">
        {t("lomavisCreator.additionalOptions.google_type")}
      </label>
      <Form.Select
        id="google-type-select"
        aria-label="Default select example"
        size="sm"
        defaultValue={null}
        onChange={(e) => {
          dispatch(
            setPostDataAttribute({
              platform: GOOGLE,
              attribute: "google_cta_type",
              value: e.target.value,
            })
          );
        }}
      >
        {GOOGLE_CTA_TYPE_CHOICES.map((choice) => (
          <option key={choice} value={choice}>
            {t(`lomavisCreator.additionalOptions.google_type_${choice}`)}
          </option>
        ))}
      </Form.Select>
      {google_cta_type == GOOGLE_CTA_TYPE_CHOICE_CALL && (
        <span className="text-muted">
          {t("lomavisCreator.additionalOptions.google_call_cta_helper")}
        </span>
      )}
      <CreatorVerticalSeparator />
      {showUrlInput && (
        <div>
          <label htmlFor="google-cta-url">
            {t("lomavisCreator.additionalOptions.google_url")}
          </label>
          <input
            id="google-cta-url"
            type="text"
            value={google_cta_url || ""}
            className="form-control form-control-sm"
            placeholder={t(
              "lomavisCreator.additionalOptions.google_url_placeholder"
            )}
            onChange={(e) => {
              dispatch(
                setPostDataAttribute({
                  platform: GOOGLE,
                  attribute: "google_cta_url",
                  value: e.target.value,
                })
              );
            }}
          />
        </div>
      )}
    </div>
  );
};
