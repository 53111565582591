import "./styles.css";
import Logo from "../../assets/images/lomavis/logo/lomavis-logo.png";
export default function GlobalLoader() {
  return (
    <div
      className="global-loader d-flex justify-content-center align-items-center flex-column w-100"
      style={{ height: "calc(100vh - 150px)" }}
    >
      <img src={Logo} alt="Lomavis Logo" width={75} className="mb-3" />
      <div className="dot-flashing" style={{ color: "#63cfe3" }} />
    </div>
  );
}
