import toast, { Toaster } from 'react-hot-toast';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useLocales } from '../../../locales';
import { errorsConfig } from '../../../config/plotErrors';
import { CHART_HEIGHT } from '../mixedChart/plotsConfig';
import ReactEcharts from 'echarts-for-react';
import createChartOptions from './options';
import PlotButtons from './plotButtons';
// eslint-disable-next-line react/prop-types
export function NewMixedChart({
  labels,
  data,
  config,
  display,
  label,
  isLoading,
  labelsLength,
  messages,
  hasError
}) {
  const MAX_POST_COUNT_HEIGHT_IN_PERCENT = 0.75;
  const { translate: t, currentLang } = useLocales();
  // ---------------------------------------------------------------------------
  const chartRef = useRef(null);
  // update labels
  const checkIfSeriesEmpty = () => {
    let empty = true;
    if (Object.keys(data).length === 0) return true;
    state?.series?.map((item) => {
      item.data.map((value) => {
        if (value != null) empty = false;
      });
      return null;
    });
    return empty;
  };
  useEffect(() => {
    if (!data || isLoading) return;
    setChartLoading(true);
    const colors = [];
    const series = [];
    let tUnit = 1;
    Object.keys(data).map((key, i) => {
      if (!config?.[key]) return null;
      const item = data[key];
      const plotConfig = config[key] || {};
      colors.push(plotConfig.hexColor);
      if (config[key]?.normalizeValues) {
        // get max value
        const data = [];
        series.map((item) => data.push(...item.data));
        const maxValue = Math.max(...data);
        // find the unit
        const postCountMax =
          Math.max(...item?.values) > 0 ? Math.max(...item?.values) : 1;
        tUnit =
          Math.floor(
            (maxValue / postCountMax) * MAX_POST_COUNT_HEIGHT_IN_PERCENT
          ) !== 0 &&
          Math.abs(
            Math.floor(
              (maxValue / postCountMax) * MAX_POST_COUNT_HEIGHT_IN_PERCENT
            )
          ) !== Infinity
            ? Math.floor(
                (maxValue / postCountMax) * MAX_POST_COUNT_HEIGHT_IN_PERCENT
              )
            : 1;
        const values = item?.values.map((item) => {
          return item ? Math.floor(tUnit * item) : 0;
        });
        series.push({
          name: plotConfig.title,
          type: plotConfig.type,
          data: values,
          // .concat(
          // new Array(
          // labels.length - item?.values.length > 0
          // ? labels.length - item?.values.length
          // : 0
          // ).fill(0)
          // ),
          color: plotConfig.hexColor,
          barMaxWidth: 20,
          tooltip: {
            formatter: function (params) {
              const { seriesName, value } = params;
              const translatedSeriesName = t(seriesName); // Translate the series name
              return `${translatedSeriesName}: ${value}`;
            },
            valueFormatter: function (value) {
              return value / tUnit;
            }
          }
        });
      } else
        series.push({
          name: plotConfig.title,
          type: plotConfig.type,
          data: item?.values,

          color: plotConfig.hexColor
        });
      return null;
    });

    setState(createChartOptions(labels, series, t, currentLang, config, tUnit));
    setTimeout(() => {
      setChartLoading(false);
    }, 400);
  }, [data, labels, isLoading]);

  const [chartLoading, setChartLoading] = useState(true);
  const [state, setState] = useState(null);

  if (isLoading || !state || chartLoading) {
    return (
      <Container
        fluid
        className="card card-bordered d-flex justify-content-center align-items-center  fade-out"
        style={{
          boxShadow: '0 1.4rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
          borderRadius: !display ? '0 0 0.625rem 0.625rem' : '0.625rem',
          height: CHART_HEIGHT + 200
        }}
      >
        <div className="spinner-border" role="status">
          <span className="sr-only">{t('loading')}</span>
        </div>
      </Container>
    );
  }

  return (
    <Row className="justify-content-end">
      <Col xs={12}>
        <div>
          <Toaster position="top-right" reverseOrder={false} />
        </div>
        <div
          className="card"
          style={{
            boxShadow: '0 1.4rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
            borderRadius: !display ? '0 0 0.625rem 0.625rem' : '0.625rem'
          }}
        >
          <div className="card-body">
            <div className="d-flex "></div>
            <Container fluid>
              <Row className="my-3">
                <Col sm={12} md={12}>
                  {display ? <h1>{label}</h1> : ''}
                  {messages?.length > 0 && false ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>
                          {messages.map((item, i) => {
                            return (
                              <div key={i}>
                                {t(errorsConfig[item].title)}
                                <br />
                                {t(errorsConfig[item].description)}
                              </div>
                            );
                          })}
                        </Tooltip>
                      }
                    >
                      <div className="text-primary d-inline-block">
                        <FontAwesomeIcon
                          icon={duotone('circle-info')}
                          size="1x"
                        />
                      </div>
                    </OverlayTrigger>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col
                  xl={10}
                  xxl={8}
                  lg={12}
                  md={12}
                  sm={12}
                  className="align-self-end"
                >
                  <Row className="d-flex justify-content-end no-gutters g-2">
                    <PlotButtons
                      chartRef={chartRef}
                      data={data}
                      config={config}
                      seriesLength={state?.series?.length}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
            <div>
              {checkIfSeriesEmpty() || hasError ? (
                <div className="d-flex justify-content-center align-items-center h-350px">
                  <div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={solid('exclamation-triangle')}
                        size="4x"
                        className="text-warning"
                      />

                      <h4 className="text-warning">
                        {t('mixedChart.error_while_loading_data')}
                      </h4>
                    </div>
                  </div>
                </div>
              ) : (
                <ReactEcharts
                  option={state}
                  ref={chartRef}
                  style={{ height: CHART_HEIGHT, width: '100%' }}
                />
              )}
            </div>
            <div className="d-flex justify-content-end"></div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default NewMixedChart;
