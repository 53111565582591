import { abbreviateNumber } from "js-abbreviation-number";
import dateFormat, { masks } from "dateformat";



const LARGE_TIME_RANGE = 115;
const createChartOptions = (labels, series, t, lang, config, tUnit) => {
   const firstDayInMonth = {};
  return {
    animationThreshold: 100,
    toolbox: {
      show: false,
      feature: {
        dataView: { show: false, readOnly: false },
        magicType: { show: false, type: ['line', 'bar'] },
        restore: { show: false },
        saveAsImage: { show: false }
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (params) {
        let tooltip = '';
        const date = new Date(params[0].axisValue);

        tooltip += `${date.toLocaleDateString(lang.value, {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })}<br>`;
        params
          .sort((a, b) => {
            const indexA = Object.keys(config).indexOf(
              a.seriesName.replace('mixedChart.', '')
            );
            const indexB = Object.keys(config).indexOf(
              b.seriesName.replace('mixedChart.', '')
            );
            return indexA - indexB;
          })
          .forEach((param) => {
            const color = param.color;
            const seriesName = t(param.seriesName);

            let value = param.value;
            if (
              config?.[param.seriesName.replace('mixedChart.', '')]
                ?.normalizeValues
            )
              value = value / tUnit;
            if (value !== null && value !== undefined)
              tooltip += `<div style="display: flex; align-items: center;"><span style="display: inline-block; width: 10px; height: 10px; background-color: ${color}; border-radius: 50%; margin-right: 5px;"></span><span>${seriesName}: ${new Intl.NumberFormat(lang.value).format(
                value
              )}</span></div>`;
            else
              tooltip += `<div style="display: flex; align-items: center;"><span style="display: inline-block; width: 10px; height: 10px; background-color: ${color}; border-radius: 50%; margin-right: 5px;"></span><span>${seriesName}: <b>-</b></span></div>`;
          });
        return tooltip;
      }
    },
    legend: {
      show: false
    },
    xAxis: [
      {
        type: 'category',
        data: labels,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          fontSize: 12,
          formatter: function (value) {
            const date = new Date(value);
            if (labels.length > LARGE_TIME_RANGE) {
              const formatedDate = dateFormat(date, "mmm");
              return `${formatedDate}`;
            }
            const formatedDate = dateFormat(date, "d mmm");
            return `${formatedDate}`;
          }
        }
      }
    ],
    yAxis: series.map((item) => {
      return {
        allowDecimals: false,
        axisLabel: {
          allowDecimals: false,
          precision: 0,
          fontSize: 12,
          formatter: function (value) {
            if (value < 1000000) {
              if (value == 0) {
                return 0;
              }
              if (value < 1) {
                return '';
              }
              return value;
            }

            return abbreviateNumber(Math.round(value), 2);
          }
        },
        splitLine: {
          show: false
        }
      };
    }),
    series: series.map((item) => {
      return {
        ...item,
        smooth: true,
        lineStyle: {
          width: 2
        },
        itemStyle: {
          borderRadius: [8, 8, 0, 0], // Rounded corners for the bars
          barMaxWidth: 5
        },
        barMaxWidth: 10,
        showSymbol: false,
      };
    }),
    grid: {
      show: false,
      right: 0,
      top: 10,
      bottom: 20,
      left: 55,
    },
  };
};

export default createChartOptions;
