"use strict";
import { Quill } from "react-quill";
const Keys = {
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  UP: 38,
  DOWN: 40
};
function _interopDefault(ex) {
  return ex && typeof ex === 'object' && 'default' in ex ? ex.default : ex;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _defineProperties(target, props) {
  for (let i = 0; i < props.length; i++) {
    const descriptor = props[i];
    descriptor.enumerable = descriptor.enumerable || false;
    descriptor.configurable = true;
    if ('value' in descriptor) descriptor.writable = true;
    Object.defineProperty(target, descriptor.key, descriptor);
  }
}

function _createClass(Constructor, protoProps, staticProps) {
  if (protoProps) _defineProperties(Constructor.prototype, protoProps);
  if (staticProps) _defineProperties(Constructor, staticProps);
  return Constructor;
}

function _extends() {
  _extends =
    Object.assign ||
    function (target) {
      for (let i = 1; i < arguments.length; i++) {
        const source = arguments[i];

        for (const key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }

      return target;
    };

  return _extends.apply(this, arguments);
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError('Super expression must either be null or a function');
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      writable: true,
      configurable: true,
    },
  });
  if (superClass) _setPrototypeOf(subClass, superClass);
}

function _getPrototypeOf(o) {
  _getPrototypeOf = Object.setPrototypeOf
    ? Object.getPrototypeOf
    : function _getPrototypeOf(o) {
        return o.__proto__ || Object.getPrototypeOf(o);
      };
  return _getPrototypeOf(o);
}

function _setPrototypeOf(o, p) {
  _setPrototypeOf =
    Object.setPrototypeOf ||
    function _setPrototypeOf(o, p) {
      o.__proto__ = p;
      return o;
    };

  return _setPrototypeOf(o, p);
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !Reflect.construct) return false;
  if (Reflect.construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Boolean.prototype.valueOf.call(
      Reflect.construct(Boolean, [], function () {})
    );
    return true;
  } catch (e) {
    return false;
  }
}

function _assertThisInitialized(self) {
  if (self === void 0) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }

  return self;
}

function _possibleConstructorReturn(self, call) {
  if (call && (typeof call === "object" || typeof call === "function")) {
    return call;
  }

  return _assertThisInitialized(self);
}

function _createSuper(Derived) {
  const hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    const Super = _getPrototypeOf(Derived);
    let result;

    if (hasNativeReflectConstruct) {
      const NewTarget = _getPrototypeOf(this).constructor;

      result = Reflect.construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _superPropBase(object, property) {
  while (!Object.prototype.hasOwnProperty.call(object, property)) {
    object = _getPrototypeOf(object);
    if (object === null) break;
  }

  return object;
}

function _get(target, property, receiver) {
  if (typeof Reflect !== "undefined" && Reflect.get) {
    _get = Reflect.get;
  } else {
    _get = function _get(target, property, receiver) {
      const base = _superPropBase(target, property);

      if (!base) return;
      const desc = Object.getOwnPropertyDescriptor(base, property);

      if (desc.get) {
        return desc.get.call(receiver);
      }

      return desc.value;
    };
  }

  return _get(target, property, receiver || target);
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === 'string') return _arrayLikeToArray(o, minLen);
  let n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === 'Object' && o.constructor) n = o.constructor.name;
  if (n === 'Map' || n === 'Set') return Array.from(o);
  if (n === 'Arguments' || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

  return arr2;
}

function _createForOfIteratorHelper(o, allowArrayLike) {
  let it =
    (typeof Symbol !== "undefined" && o[Symbol.iterator]) || o["@@iterator"];

  if (!it) {
    if (
      Array.isArray(o) ||
      (it = _unsupportedIterableToArray(o)) ||
      (allowArrayLike && o && typeof o.length === "number")
    ) {
      if (it) o = it;
      let i = 0;

      const F = function () {};

      return {
        s: F,
        n: function () {
          if (i >= o.length)
            return {
              done: true,
            };
          return {
            done: false,
            value: o[i++],
          };
        },
        e: function (e) {
          throw e;
        },
        f: F,
      };
    }

    throw new TypeError(
      "Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."
    );
  }

  let normalCompletion = true;
  let didErr = false;
  let err;
  return {
    s: function () {
      it = it.call(o);
    },
    n: function () {
      const step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function (e) {
      didErr = true;
      err = e;
    },
    f: function () {
      try {
        if (!normalCompletion && it.return != null) it.return();
      } finally {
        if (didErr) throw err;
      }
    },
  };
}

function attachDataValues(element, data, dataAttributes) {
  const mention = element;
  Object.keys(data).forEach(function (key) {
    if (dataAttributes.indexOf(key) > -1) {
      mention.dataset[key] = data[key];
    } else {
      delete mention.dataset[key];
    }
  });
  return mention;
}

function getMentionCharIndex(text, mentionDenotationChars) {
  return mentionDenotationChars.reduce(
    function (prev, mentionChar) {
      const mentionCharIndex = text.lastIndexOf(mentionChar);

      if (mentionCharIndex > prev.mentionCharIndex) {
        return {
          mentionChar,
          mentionCharIndex,
        };
      }

      return {
        mentionChar: prev.mentionChar,
        mentionCharIndex: prev.mentionCharIndex,
      };
    },
    {
      mentionChar: null,
      mentionCharIndex: -1,
    }
  );
}

function hasValidChars(text, allowedChars) {
  return allowedChars.test(text);
}

function hasValidMentionCharIndex(mentionCharIndex, text, isolateChar) {
  if (mentionCharIndex > -1) {
    if (
      isolateChar &&
      !(mentionCharIndex === 0 || !!text[mentionCharIndex - 1].match(/\s/g))
    ) {
      return false;
    }

    return true;
  }

  return false;
}

const Embed = Quill.import("blots/embed");

const MentionBlot = /* #__PURE__ */ (function (_Embed) {
  _inherits(MentionBlot, _Embed);

  const _super = _createSuper(MentionBlot);

  function MentionBlot(scroll, node) {
    let _this;

    _classCallCheck(this, MentionBlot);

    _this = _super.call(this, scroll, node);
    _this.clickHandler = null;
    return _this;
  }

  _createClass(
    MentionBlot,
    [
      {
        key: 'attach',
        value: function attach() {
          const _this2 = this;

          _get(_getPrototypeOf(MentionBlot.prototype), 'attach', this).call(
            this
          );

          this.clickHandler = function (e) {
            const event = new Event("mention-clicked", {
              bubbles: true,
              cancelable: true,
            });
            event.value = _extends({}, _this2.domNode.dataset);
            event.event = e;
            window.dispatchEvent(event);
            e.preventDefault();
          };

          this.domNode.addEventListener('click', this.clickHandler, false);
        }
      },
      {
        key: 'detach',
        value: function detach() {
          _get(_getPrototypeOf(MentionBlot.prototype), 'detach', this).call(
            this
          );

          if (this.clickHandler) {
            this.domNode.removeEventListener("click", this.clickHandler);
            this.clickHandler = null;
          }
        },
      },
    ],
    [
      {
        key: "create",
        value: function create(data) {
          const node = _get(_getPrototypeOf(MentionBlot), "create", this).call(
            this
          );
          node.className += " text-primary fw-bolder ";

          const denotationChar = document.createElement("span");
          denotationChar.className = "ql-mention-denotation-char ";
          denotationChar.innerHTML =
            data.show_denotation_char === "true" ? "@" : "";
          node.appendChild(denotationChar);
          node.innerHTML += data.display_name;
          node.innerHTML += `<i class="fa-brands ${data.platform_css_class} ms-1 text-primary"></i>`;
          return MentionBlot.setDataValues(node, data);
        }
      },
      {
        key: 'setDataValues',
        value: function setDataValues(element, data) {
          const domNode = element;
          Object.keys(data).forEach(function (key) {
            domNode.dataset[key] = data[key];
          });
          return domNode;
        },
      },
      {
        key: "value",
        value: function value(domNode) {
          return domNode.dataset;
        },
      },
    ]
  );

  return MentionBlot;
})(Embed);

MentionBlot.blotName = 'mention';
MentionBlot.tagName = 'span';
MentionBlot.className = 'mention';
Quill.register(MentionBlot);

const Mention = /* #__PURE__ */ (function () {
  function Mention(quill, options) {
    const _this = this;

    _classCallCheck(this, Mention);

    this.isOpen = false;
    this.itemIndex = 0;
    this.mentionCharPos = null;
    this.cursorPos = null;
    this.values = [];
    this.suspendMouseEnter = false; // this token is an object that may contains one key "abandoned", set to
    // true when the previous source call should be ignored in favor or a
    // more recent execution.  This token will be null unless a source call
    // is in progress.

    this.existingSourceExecutionToken = null;
    this.quill = quill;
    this.options = {
      source: null,
      renderItem: function renderItem(item) {
        return "".concat(item.value);
      },
      renderLoading: function renderLoading() {
        return null;
      },
      onSelect: function onSelect(item, insertItem) {
        insertItem(item);
      },
      mentionDenotationChars: ['@'],
      showDenotationChar: true,
      allowedChars: /^[a-zA-Z0-9_]*$/,
      minChars: 0,
      maxChars: 31,
      offsetTop: 2,
      offsetLeft: 0,
      isolateCharacter: false,
      fixMentionsToQuill: false,
      positioningStrategy: "normal",
      defaultMenuOrientation: "bottom",
      blotName: "mention",
      dataAttributes: [
        "id",
        "value",
        "denotationChar",
        "link",
        "target",
        "disabled",
      ],
      linkTarget: "_blank",
      onOpen: function onOpen() {
        return true;
      },
      onClose: function onClose() {
        return true;
      },
      mentionListStyleOptions: options.mentionListStyleOptions
        ? options.mentionListStyleOptions
        : "",
      listItemClass: "ql-mention-list-item",
      mentionContainerClassOptions: options.mentionContainerClassOptions
        ? options.mentionContainerClassOptions
        : [],
      mentionContainerStyleOptions: options.mentionContainerStyleOptions
        ? options.mentionContainerStyleOptions
        : "",
      mentionContainerClass: "ql-mention-list-container",
      mentionListClass: "ql-mention-list",
      spaceAfterInsert: true,
      selectKeys: [Keys.ENTER],
    };

    _extends(this.options, options, {
      dataAttributes: Array.isArray(options.dataAttributes)
        ? this.options.dataAttributes.concat(options.dataAttributes)
        : this.options.dataAttributes,
    }); // create mention container

    this.mentionContainer = document.createElement("div");
    this.mentionContainer.className = this.options.mentionContainerClass
      ? this.options.mentionContainerClass
      : "";
    this.mentionContainer.classList.add(
      ...this.options.mentionContainerClassOptions
    );
    this.mentionContainer.style.cssText =
      this.options.mentionContainerStyleOptions;
    this.mentionContainer.style.cssText += 'display: none; position: absolute;';
    this.mentionContainer.onmousemove = this.onContainerMouseMove.bind(this);

    if (this.options.fixMentionsToQuill) {
      this.mentionContainer.style.width = 'auto';
    }

    this.mentionList = document.createElement('ul');
    this.mentionList.id = 'quill-mention-list';
    quill.root.setAttribute('aria-owns', 'quill-mention-list');
    this.mentionList.className = this.options.mentionListClass
      ? this.options.mentionListClass
      : '';
    this.mentionList.style.cssText = this.options.mentionListStyleOptions;
    this.mentionContainer.appendChild(this.mentionList);
    quill.on('text-change', this.onTextChange.bind(this));
    quill.on('selection-change', this.onSelectionChange.bind(this)); // Pasting doesn't fire selection-change after the pasted text is
    // inserted, so here we manually trigger one
    quill.container.addEventListener('paste', function () {
      setTimeout(function () {
        const range = quill.getSelection();

        _this.onSelectionChange(range);
      });
    });
    quill.keyboard.addBinding(
      {
        key: Keys.TAB,
      },
      this.selectHandler.bind(this)
    );
    quill.keyboard.bindings[Keys.TAB].unshift(
      quill.keyboard.bindings[Keys.TAB].pop()
    );

    const _iterator = _createForOfIteratorHelper(this.options.selectKeys);
    let _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done; ) {
        const selectKey = _step.value;
        quill.keyboard.addBinding(
          {
            key: selectKey,
          },
          this.selectHandler.bind(this)
        );
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    quill.keyboard.bindings[Keys.ENTER].unshift(
      quill.keyboard.bindings[Keys.ENTER].pop()
    );
    quill.keyboard.addBinding(
      {
        key: Keys.ESCAPE,
      },
      this.escapeHandler.bind(this)
    );
    quill.keyboard.addBinding(
      {
        key: Keys.UP,
      },
      this.upHandler.bind(this)
    );
    quill.keyboard.addBinding(
      {
        key: Keys.DOWN,
      },
      this.downHandler.bind(this)
    );
  }

  _createClass(Mention, [
    {
      key: "selectHandler",
      value: function selectHandler() {
        if (this.isOpen && !this.existingSourceExecutionToken) {
          this.selectItem();
          return false;
        }

        return true;
      }
    },
    {
      key: 'escapeHandler',
      value: function escapeHandler() {
        if (this.isOpen) {
          if (this.existingSourceExecutionToken) {
            this.existingSourceExecutionToken.abandoned = true;
          }

          this.hideMentionList();
          return false;
        }

        return true;
      }
    },
    {
      key: 'upHandler',
      value: function upHandler() {
        if (this.isOpen && !this.existingSourceExecutionToken) {
          this.prevItem();
          return false;
        }

        return true;
      }
    },
    {
      key: 'downHandler',
      value: function downHandler() {
        if (this.isOpen && !this.existingSourceExecutionToken) {
          this.nextItem();
          return false;
        }

        return true;
      }
    },
    {
      key: 'showMentionList',
      value: function showMentionList() {
        if (this.options.positioningStrategy === 'fixed') {
          document.body.appendChild(this.mentionContainer);
        } else {
          this.quill.container.appendChild(this.mentionContainer);
        }

        this.mentionContainer.style.visibility = 'hidden';
        this.mentionContainer.style.display = '';
        this.mentionContainer.scrollTop = 0;
        this.setMentionContainerPosition();
        this.setIsOpen(true);
      }
    },
    {
      key: 'hideMentionList',
      value: function hideMentionList() {
        this.mentionContainer.style.display = 'none';
        this.mentionContainer.remove();
        this.setIsOpen(false);
        this.quill.root.removeAttribute('aria-activedescendant');
      }
    },
    {
      key: 'highlightItem',
      value: function highlightItem() {
        const scrollItemInView =
          arguments.length > 0 && arguments[0] !== undefined
            ? arguments[0]
            : true;

        for (let i = 0; i < this.mentionList.childNodes.length; i += 1) {
          this.mentionList.childNodes[i].classList.remove('selected');
        }

        if (
          this.itemIndex === -1 ||
          this.mentionList.childNodes[this.itemIndex].dataset.disabled ===
            "true"
        ) {
          return;
        }

        this.mentionList.childNodes[this.itemIndex].classList.add("selected");
        this.quill.root.setAttribute(
          "aria-activedescendant",
          this.mentionList.childNodes[this.itemIndex].id
        );

        if (scrollItemInView) {
          const itemHeight =
            this.mentionList.childNodes[this.itemIndex].offsetHeight;
          const itemPos = this.mentionList.childNodes[this.itemIndex].offsetTop;
          const containerTop = this.mentionContainer.scrollTop;
          const containerBottom =
            containerTop + this.mentionContainer.offsetHeight;

          if (itemPos < containerTop) {
            // Scroll up if the item is above the top of the container
            this.mentionContainer.scrollTop = itemPos;
          } else if (itemPos > containerBottom - itemHeight) {
            // scroll down if any part of the element is below the bottom of the container
            this.mentionContainer.scrollTop +=
              itemPos - containerBottom + itemHeight;
          }
        }
      }
    },
    {
      key: 'getItemData',
      value: function getItemData() {
        const link = this.mentionList.childNodes[this.itemIndex].dataset.link;
        const hasLinkValue = typeof link !== 'undefined';
        const itemTarget =
          this.mentionList.childNodes[this.itemIndex].dataset.target;

        if (hasLinkValue) {
          this.mentionList.childNodes[this.itemIndex].dataset.value =
            '<a href="'
              .concat(link, '" target=')
              .concat(itemTarget || this.options.linkTarget, '>')
              .concat(
                this.mentionList.childNodes[this.itemIndex].dataset.value
              );
        }

        return this.mentionList.childNodes[this.itemIndex].dataset;
      }
    },
    {
      key: 'onContainerMouseMove',
      value: function onContainerMouseMove() {
        this.suspendMouseEnter = false;
      }
    },
    {
      key: 'selectItem',
      value: function selectItem() {
        const _this2 = this;

        if (this.itemIndex === -1) {
          return;
        }

        const data = this.getItemData();

        if (data.disabled) {
          return;
        }

        this.options.onSelect(data, function (asyncData) {
          _this2.insertItem(asyncData);
        });
        this.hideMentionList();
      }
    },
    {
      key: 'insertItem',
      value: function insertItem(data, programmaticInsert) {
        const render = data;

        if (render === null) {
          return;
        }

        if (!this.options.showDenotationChar) {
          render.denotationChar = "";
        }

        let insertAtPos;

        if (!programmaticInsert) {
          insertAtPos = this.mentionCharPos;
          this.quill.deleteText(
            this.mentionCharPos,
            this.cursorPos - this.mentionCharPos,
            Quill.sources.USER
          );
        } else {
          insertAtPos = this.cursorPos;
        }

        this.quill.insertEmbed(
          insertAtPos,
          this.options.blotName,
          render,
          Quill.sources.USER
        );

        if (this.options.spaceAfterInsert) {
          this.quill.insertText(insertAtPos + 1, ' ', Quill.sources.USER); // setSelection here sets cursor position

          this.quill.setSelection(insertAtPos + 2, Quill.sources.USER);
        } else {
          this.quill.setSelection(insertAtPos + 1, Quill.sources.USER);
        }

        this.hideMentionList();
      }
    },
    {
      key: 'onItemMouseEnter',
      value: function onItemMouseEnter(e) {
        if (this.suspendMouseEnter) {
          return;
        }

        const index = Number(e.target.dataset.index);

        if (!Number.isNaN(index) && index !== this.itemIndex) {
          this.itemIndex = index;
          this.highlightItem(false);
        }
      }
    },
    {
      key: 'onDisabledItemMouseEnter',
      value: function onDisabledItemMouseEnter(e) {
        if (this.suspendMouseEnter) {
          return;
        }

        this.itemIndex = -1;
        this.highlightItem(false);
      }
    },
    {
      key: 'onItemClick',
      value: function onItemClick(e) {
        if (e.button !== 0) {
          return;
        }

        e.preventDefault();
        e.stopImmediatePropagation();
        $(this.mentionList).find('[data-bs-toggle="tooltip"]').tooltip("hide");
        this.itemIndex = e.currentTarget.dataset.index;
        this.highlightItem();
        this.selectItem();
      },
    },
    {
      key: "onItemMouseDown",
      value: function onItemMouseDown(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
      },
    },
    {
      key: "renderLoading",
      value: function renderLoading() {
        this.mentionList.className = this.mentionList.className.replace(
          "py-2",
          " "
        );
        const renderedLoading = this.options.renderLoading();

        if (!renderedLoading) {
          return;
        }

        if (
          this.mentionContainer.getElementsByClassName("ql-mention-loading")
            .length > 0
        ) {
          this.showMentionList();
          return;
        }

        this.mentionList.innerHTML = '';
        const loadingDiv = document.createElement('div');
        loadingDiv.className = 'ql-mention-loading';
        loadingDiv.innerHTML = this.options.renderLoading();
        this.mentionContainer.append(loadingDiv);
        this.showMentionList();
      }
    },
    {
      key: 'removeLoading',
      value: function removeLoading() {
        const loadingDiv =
          this.mentionContainer.getElementsByClassName('ql-mention-loading');

        if (loadingDiv.length > 0) {
          loadingDiv[0].remove();
        }
      },
    },
    {
      key: "renderList",
      value: function renderList(mentionChar, data, searchTerm) {
        if (data && data.length > 0) {
          this.removeLoading();
          this.mentionList.className += " py-2 ";
          this.values = data;
          this.mentionList.innerHTML = "";
          let initialSelection = -1;

          for (let i = 0; i < data.length; i += 1) {
            const li = document.createElement("li");
            li.id = "quill-mention-item-" + i;
            li.className = this.options.listItemClass
              ? this.options.listItemClass
              : "";

            if (data[i].disabled) {
              li.className += " disabled";
              li.setAttribute("aria-hidden", "true");
            } else if (initialSelection === -1) {
              initialSelection = i;
            }

            li.dataset.index = i;
            li.innerHTML = this.options.renderItem(data[i], searchTerm);

            if (!data[i].disabled) {
              li.onmouseenter = this.onItemMouseEnter.bind(this);
              li.onmouseup = this.onItemClick.bind(this);
              li.onmousedown = this.onItemMouseDown.bind(this);
            } else {
              li.onmouseenter = this.onDisabledItemMouseEnter.bind(this);
            }

            li.dataset.denotationChar = mentionChar;
            this.mentionList.appendChild(
              attachDataValues(li, data[i], this.options.dataAttributes)
            );
          }

          this.itemIndex = initialSelection;
          this.highlightItem();
          this.showMentionList();
          $(this.mentionList).find('[data-bs-toggle="tooltip"]').tooltip();
        } else {
          this.hideMentionList();
        }
        this.setMentionContainerPosition_Normal();
      },
    },
    {
      key: "nextItem",
      value: function nextItem() {
        let increment = 0;
        let newIndex;

        do {
          increment++;
          newIndex = (this.itemIndex + increment) % this.values.length;
          var disabled =
            this.mentionList.childNodes[newIndex].dataset.disabled === 'true';

          if (increment === this.values.length + 1) {
            // we've wrapped around w/o finding an enabled item
            newIndex = -1;
            break;
          }
        } while (disabled);

        this.itemIndex = newIndex;
        this.suspendMouseEnter = true;
        this.highlightItem();
      }
    },
    {
      key: 'prevItem',
      value: function prevItem() {
        let decrement = 0;
        let newIndex;

        do {
          decrement++;
          newIndex =
            (this.itemIndex + this.values.length - decrement) %
            this.values.length;
          var disabled =
            this.mentionList.childNodes[newIndex].dataset.disabled === 'true';

          if (decrement === this.values.length + 1) {
            // we've wrapped around w/o finding an enabled item
            newIndex = -1;
            break;
          }
        } while (disabled);

        this.itemIndex = newIndex;
        this.suspendMouseEnter = true;
        this.highlightItem();
      }
    },
    {
      key: 'containerBottomIsNotVisible',
      value: function containerBottomIsNotVisible(topPos, containerPos) {
        const mentionContainerBottom =
          topPos + this.mentionContainer.offsetHeight + containerPos.top;
        return mentionContainerBottom > window.pageYOffset + window.innerHeight;
      }
    },
    {
      key: 'containerRightIsNotVisible',
      value: function containerRightIsNotVisible(leftPos, containerPos) {
        if (this.options.fixMentionsToQuill) {
          return false;
        }

        const rightPos =
          leftPos + this.mentionContainer.offsetWidth + containerPos.left;
        const browserWidth =
          window.pageXOffset + document.documentElement.clientWidth;
        return rightPos > browserWidth;
      }
    },
    {
      key: 'setIsOpen',
      value: function setIsOpen(isOpen) {
        if (this.isOpen !== isOpen) {
          if (isOpen) {
            this.options.onOpen();
          } else {
            this.options.onClose();
          }

          this.isOpen = isOpen;
        }
      }
    },
    {
      key: 'setMentionContainerPosition',
      value: function setMentionContainerPosition() {
        if (this.options.positioningStrategy === 'fixed') {
          this.setMentionContainerPosition_Fixed();
        } else {
          this.setMentionContainerPosition_Normal();
        }
      }
    },
    {
      key: 'setMentionContainerPosition_Normal',
      value: function setMentionContainerPosition_Normal() {
        const _this3 = this;

        const containerPos = this.quill.container.getBoundingClientRect();
        const mentionCharPos = this.quill.getBounds(this.mentionCharPos);
        const containerHeight = this.mentionContainer.offsetHeight;
        let topPos = this.options.offsetTop;
        let leftPos = this.options.offsetLeft; // handle horizontal positioning

        if (this.options.fixMentionsToQuill) {
          const rightPos = 0;
          this.mentionContainer.style.right = ''.concat(rightPos, 'px');
        } else {
          leftPos += mentionCharPos.left;
        }

        // Ensure the mention container stays within the right boundary of the Quill container
        const containerRightEdge = leftPos + this.mentionContainer.offsetWidth;
        const quillContainerWidth = containerPos.width;
        if (containerRightEdge > quillContainerWidth) {
          // Shift the mention container to the left so it fits within the Quill container
          leftPos -=
            containerRightEdge - quillContainerWidth - this.options.offsetLeft;
          // Ensure the leftPos does not go beyond the left boundary
          if (leftPos < 0) {
            leftPos = 0;
          }
        }

        // handle vertical positioning
        if (this.options.defaultMenuOrientation === "top") {
          // Attempt to align the mention container with the top of the quill editor
          if (this.options.fixMentionsToQuill) {
            topPos = -1 * (containerHeight + this.options.offsetTop);
          } else {
            topPos =
              mentionCharPos.top - (containerHeight + this.options.offsetTop);
          }
          // default to bottom if the top is not visible
          if (topPos + containerPos.top <= 0) {
            let overMentionCharPos = this.options.offsetTop;

            if (this.options.fixMentionsToQuill) {
              overMentionCharPos += containerPos.height;
            } else {
              overMentionCharPos += mentionCharPos.bottom;
            }

            topPos = overMentionCharPos;
          }
        } else {
          // Attempt to align the mention container with the bottom of the quill editor
          if (this.options.fixMentionsToQuill) {
            topPos += containerPos.height;
          } else {
            topPos += mentionCharPos.bottom;
          }
          // default to the top if the bottom is not visible
          if (this.containerBottomIsNotVisible(topPos, containerPos)) {
            let _overMentionCharPos = this.options.offsetTop * -1;

            if (!this.options.fixMentionsToQuill) {
              _overMentionCharPos += mentionCharPos.top;
            }

            topPos = _overMentionCharPos - containerHeight;
          }
        }

        if (topPos >= 0) {
          this.options.mentionContainerClass
            .split(' ')
            .forEach(function (className) {
              _this3.mentionContainer.classList.add(
                ''.concat(className, '-bottom')
              );

              _this3.mentionContainer.classList.remove(
                ''.concat(className, '-top')
              );
            });
        } else {
          this.options.mentionContainerClass
            .split(' ')
            .forEach(function (className) {
              _this3.mentionContainer.classList.add(
                ''.concat(className, '-top')
              );

              _this3.mentionContainer.classList.remove(
                ''.concat(className, '-bottom')
              );
            });
        }

        this.mentionContainer.style.top = "".concat(topPos, "px");
        this.mentionContainer.style.left = "".concat(leftPos, "px");
        this.mentionContainer.style.visibility = "visible";
      },
    },
    {
      key: "setMentionContainerPosition_Fixed",
      value: function setMentionContainerPosition_Fixed() {
        const _this4 = this;

        this.mentionContainer.style.position = "fixed";
        this.mentionContainer.style.height = null;
        const containerPos = this.quill.container.getBoundingClientRect();
        const mentionCharPos = this.quill.getBounds(this.mentionCharPos);
        const mentionCharPosAbsolute = {
          left: containerPos.left + mentionCharPos.left,
          top: containerPos.top + mentionCharPos.top,
          width: 0,
          height: mentionCharPos.height,
        }; // Which rectangle should it be relative to

        const relativeToPos = this.options.fixMentionsToQuill
          ? containerPos
          : mentionCharPosAbsolute;
        let topPos = this.options.offsetTop;
        let leftPos = this.options.offsetLeft; // handle horizontal positioning

        if (this.options.fixMentionsToQuill) {
          const rightPos = relativeToPos.right;
          this.mentionContainer.style.right = ''.concat(rightPos, 'px');
        } else {
          leftPos += relativeToPos.left; // if its off the righ edge, push it back

          if (
            leftPos + this.mentionContainer.offsetWidth >
            document.documentElement.clientWidth
          ) {
            leftPos -=
              leftPos +
              this.mentionContainer.offsetWidth -
              document.documentElement.clientWidth;
          }
        }

        const availableSpaceTop = relativeToPos.top;
        const availableSpaceBottom =
          document.documentElement.clientHeight -
          (relativeToPos.top + relativeToPos.height);
        const fitsBottom =
          this.mentionContainer.offsetHeight <= availableSpaceBottom;
        const fitsTop = this.mentionContainer.offsetHeight <= availableSpaceTop;
        let placement;

        if (this.options.defaultMenuOrientation === "top" && fitsTop) {
          placement = "top";
        } else if (
          this.options.defaultMenuOrientation === "bottom" &&
          fitsBottom
        ) {
          placement = "bottom";
        } else {
          // it doesnt fit either so put it where there's the most space
          placement =
            availableSpaceBottom > availableSpaceTop ? "bottom" : "top";
        }

        if (placement === "bottom") {
          topPos = relativeToPos.top + relativeToPos.height;

          if (!fitsBottom) {
            // shrink it to fit
            // 3 is a bit of a fudge factor so it doesnt touch the edge of the screen
            this.mentionContainer.style.height =
              availableSpaceBottom - 3 + 'px';
          }

          this.options.mentionContainerClass
            .split(' ')
            .forEach(function (className) {
              _this4.mentionContainer.classList.add(
                ''.concat(className, '-bottom')
              );

              _this4.mentionContainer.classList.remove(
                ''.concat(className, '-top')
              );
            });
        } else {
          topPos = relativeToPos.top - this.mentionContainer.offsetHeight;

          if (!fitsTop) {
            // shrink it to fit
            // 3 is a bit of a fudge factor so it doesnt touch the edge of the screen
            this.mentionContainer.style.height = availableSpaceTop - 3 + 'px';
            topPos = 3;
          }

          this.options.mentionContainerClass
            .split(' ')
            .forEach(function (className) {
              _this4.mentionContainer.classList.add(
                ''.concat(className, '-top')
              );

              _this4.mentionContainer.classList.remove(
                ''.concat(className, '-bottom')
              );
            });
        }

        this.mentionContainer.style.top = ''.concat(topPos, 'px');
        this.mentionContainer.style.left = ''.concat(leftPos, 'px');
        this.mentionContainer.style.visibility = 'visible';
      }
    },
    {
      key: 'getTextBeforeCursor',
      value: function getTextBeforeCursor() {
        const startPos = Math.max(0, this.cursorPos - this.options.maxChars);
        const textBeforeCursorPos = this.quill.getText(
          startPos,
          this.cursorPos - startPos
        );
        return textBeforeCursorPos;
      }
    },
    {
      key: 'onSomethingChange',
      value: function onSomethingChange() {
        const _this5 = this;

        const range = this.quill.getSelection();
        if (range == null) return;
        this.cursorPos = range.index;
        const textBeforeCursor = this.getTextBeforeCursor();

        const _getMentionCharIndex = getMentionCharIndex(
          textBeforeCursor,
          this.options.mentionDenotationChars
        );
        const mentionChar = _getMentionCharIndex.mentionChar;
        const mentionCharIndex = _getMentionCharIndex.mentionCharIndex;

        if (
          hasValidMentionCharIndex(
            mentionCharIndex,
            textBeforeCursor,
            this.options.isolateCharacter
          )
        ) {
          const mentionCharPos =
            this.cursorPos - (textBeforeCursor.length - mentionCharIndex);
          this.mentionCharPos = mentionCharPos;
          const textAfter = textBeforeCursor.substring(
            mentionCharIndex + mentionChar.length
          );

          if (
            textAfter.length >= this.options.minChars &&
            hasValidChars(textAfter, this.getAllowedCharsRegex(mentionChar))
          ) {
            if (this.existingSourceExecutionToken) {
              this.existingSourceExecutionToken.abandoned = true;
            }

            this.renderLoading();
            const sourceRequestToken = {
              abandoned: false,
            };
            this.existingSourceExecutionToken = sourceRequestToken;
            this.options.source(
              textAfter,
              function (data, searchTerm) {
                if (sourceRequestToken.abandoned) {
                  return;
                }

                _this5.existingSourceExecutionToken = null;

                _this5.renderList(mentionChar, data, searchTerm);
              },
              mentionChar
            );
          } else {
            this.hideMentionList();
          }
        } else {
          this.hideMentionList();
        }
      }
    },
    {
      key: 'getAllowedCharsRegex',
      value: function getAllowedCharsRegex(denotationChar) {
        if (this.options.allowedChars instanceof RegExp) {
          return this.options.allowedChars;
        } else {
          return this.options.allowedChars(denotationChar);
        }
      },
    },
    {
      key: "onTextChange",
      value: function onTextChange(delta, oldDelta, source) {
        if (source === "user") {
          this.onSomethingChange();
        }
      },
    },
    {
      key: "onSelectionChange",
      value: function onSelectionChange(range) {
        if (range && range.length === 0) {
          this.onSomethingChange();
        } else {
          this.hideMentionList();
        }
      }
    },
    {
      key: 'openMenu',
      value: function openMenu(denotationChar) {
        const selection = this.quill.getSelection(true);
        this.quill.insertText(selection.index, denotationChar);
        this.quill.blur();
        this.quill.focus();
      },
    },
  ]);

  return Mention;
})();

Quill.register('modules/mention', Mention);

const Delta = Quill.import('delta');
const Clipboard = Quill.import('modules/clipboard');

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const text = e.clipboardData.getData('text/plain');
    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert(text);
    // when creating the delta, new lines will be duplicated. Here, we change it back to a single new line
    for (let i = 0; i < delta.ops.length; i++) {
      const ops = delta.ops[i];
      if (ops.insert) {
        delta.ops[i].insert = delta.ops[i].insert.replace(/\n\n/g, '\n');
      }
    }

    const index = text.length + range.index;
    const length = 0;
    this.quill.updateContents(delta, "user");
    this.quill.setSelection(index, length, "copyPaste");
    this.quill.scrollIntoView();
    // this.quill.insertText(this.quill.getLength() - 1, this.quill.getText(), 'user')  // force reload of highlighting
  }
}

Quill.register("modules/clipboard", PlainClipboard, true);

// We need this workaround to handle autocomplete and spellchecking. If not used, the text will disappear
// if text gets corrected or similiar.
// https://github.com/quilljs/quill/issues/2096
const Inline = Quill.import("blots/inline");

class CustomAttributes extends Inline {
  constructor(domNode, value) {
    super(domNode, value);

    const span = this.replaceWith(new Inline(Inline.create()));

    span.children.forEach((child) => {
      if (child.attributes) child.attributes.copy(span);
      if (child.unwrap) child.unwrap();
    });

    // here we apply every attribute from <font> tag to span as a style
    Object.keys(domNode.attributes).forEach(function (key) {
      if (domNode.attributes[key].name != 'style') {
        const value = domNode.attributes[key].value;
        let name = domNode.attributes[key].name;
        if (name == 'face') name = 'font-family';
        span.format(name, value);
      }
    });

    this.remove();

    return span;
  }
}

CustomAttributes.blotName = 'customAttributes';
CustomAttributes.tagName = 'FONT';

Quill.register(CustomAttributes, true);
export default Quill;
